import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {CommonModule, DatePipe} from '@angular/common';
import { StudentsComponent } from './components/students/students.component';
import { HeaderComponent } from './components/shared/header/header.component';
import {StudentDetailsComponent} from './components/student-details/student-details.component';
import {CoreService} from './services/core.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginComponent} from './components/login/login.component';
import {CalendarComponent} from './components/calendar/calendar.component';
import {Calendar} from '@ionic-native/calendar/ngx';
import {QuickMenuComponent} from './components/shared/quick-menu/quick-menu.component';
import {QuickMenuIconComponent} from './components/shared/quick-menu-icon/quick-menu-icon.component';
import {LectureSubjectsComponent} from './components/lecture-subjects/lecture-subjects.component';
import {LectureNewComponent} from './components/lecture-new/lecture-new.component';
import {LectureSubjectFileComponent} from './components/lecture-subject-file/lecture-subject-file.component';
import {ClassSelectorComponent} from './components/shared/class-selector/class-selector.component';
import {SchoolSelectorComponent} from './components/shared/school-selector/school-selector.component';
import {StudentParentsComponent} from './components/student-parents/student-parents.component';
import {GenderSelectorComponent} from './components/shared/gender-selector/gender-selector.component';
import {CallModalComponent} from './components/shared/call-modal/call-modal.component';
import {CallNumber} from '@ionic-native/call-number/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import {Contacts} from '@ionic-native/contacts';
import { DatePickerModule } from 'ionic4-date-picker';
import {PickerDateTimeComponent} from './components/shared/picker-date-time/picker-date-time.component';
import {CalendarEventDetailsComponent} from './components/calendar-event-details/calendar-event-details.component';
import {PickerDateTimeContainerComponent} from './components/shared/picker-date-time-container/picker-date-time-container.component';
import {SchoolSelectorContainerComponent} from './components/shared/school-selector-container/school-selector-container.component';
import {ImageBrowserComponent} from './components/shared/image-browser/image-browser.component';
import {File} from '@ionic-native/file/ngx';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {SubmitSectionData} from './components/shared/submitsectiondata.model';
import {SubmitSectionComponent} from './components/shared/submit-section/submit-section.component';
import {AndroidPermissions} from '@ionic-native/android-permissions/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import {FilePath} from '@ionic-native/file-path/ngx';
import {Base64} from '@ionic-native/base64/ngx';
import {FileUploadComponent} from './components/shared/file-upload/file-upload.component';
import {StudentsImportComponent} from './components/students-import/students-import.component';
import {GenderAvatarComponent} from './components/shared/gender-avatar/gender-avatar.component';
import {StudentDocumentsComponent} from './components/student-documents/student-documents.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {HomePage} from './components/home/home.page';
import {StudentVbookComponent} from './components/student-vbook/student-vbook.component';
import {ResourcesService} from './services/resources.service';
import {VaccineLotsComponent} from './components/vaccine-lots/vaccine-lots.component';
import {ToastNotificationComponent} from './components/shared/toast-notification/toast-notification.component';
import {DocumentsMyComponent} from './components/documents-my/documents-my.component';
import {ScreeningService} from './services/screening.service';
import {ScreeningsComponent} from './components/screenings/screenings.component';
import {SchoolsComponent} from './components/schools/schools.component';
import {SchoolMultiSelectorComponent} from './components/shared/school-multi-selector/school-multi-selector.component';
import {ModalScolioSelectorComponent} from './components/shared/modal-scolio-selector/modal-scolio-selector.component';
import {SingleValueSelectorComponent} from './components/shared/single-value-selector/single-value-selector.component';
import {VisitorService} from './services/visitor.service';
import {RegistrationService} from './services/registration.service';
import {VaccineMenuComponent} from './components/vaccine-menu/vaccine-menu.component';
import {DoctorReferenceModalComponent} from './components/shared/doctor-reference-modal/doctor-reference-modal.component';
import {VaccineService} from './services/vaccine.service';
import {DoctorReferencesComponent} from './components/doctor-references/doctor-references.component';
import {ConfirmModalComponent} from './components/shared/confirm-modal/confirm-modal.component';
import {VaccineShotComponent} from './components/vaccine-shot/vaccine-shot.component';
import {VaccineLotSelectorComponent} from './components/shared/vaccine-lot-selector/vaccine-lot-selector.component';
import {LectureSubjectNewComponent} from './components/lecture-subject-new/lecture-subject-new.component';
import {LectureService} from './services/lecture.service';
import {LectureMenuComponent} from './components/lecture-menu/lecture-menu.component';
import {SmsModalComponent} from './components/shared/sms-modal/sms-modal.component';
import {MessageService} from './services/message.service';
import {ScreeningMenuComponent} from './components/screening-menu/screening-menu.component';
import {DocumentsFormsComponent} from './components/documents-forms/documents-forms.component';
import {DocumentsReportsComponent} from './components/documents-reports/documents-reports.component';
import {DocumentsService} from './services/documents.service';
import {ScreeningAddComponent} from './components/screening-add/screening-add.component';
import {DocumentsFormsSmsAddComponent} from './components/documents-forms-sms-add/documents-forms-sms-add.component';
import {TranslationsCommonService} from './services/translations.common.service';
import {ModalActionStudentsComponent} from './components/shared/modal-action-students/modal-action-students.component';
import {ModalVaccineLotsAddComponent} from './components/shared/modal-vaccine-lots-add/modal-vaccine-lots-add.component';
import {CachingService} from './services/caching.service';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import {CacheTestComponent} from './components/cache-test/cache-test.component';
import {CommonService} from './services/common.service';
import {SettingsComponent} from './components/settings/settings.component';
import {ObserveVisibilityDirective} from './directives/observevisibility';
import {ScreeningReportComponent} from './components/screening-report/screening-report.component';
import {CalendarService} from './services/calendar.service';
import {LogService} from './services/log.service';
import {RegistrationComponent} from './components/registration/registration.component';
import {UpdateLogComponent} from './components/update-log/update-log.component';
import { SMS } from '@awesome-cordova-plugins/sms/ngx';
import {HomepageService} from './services/homepage.service';
import {ScreeningsMissingComponent} from './components/screenings-missing/screenings-missing.component';
import {SchoolsAddComponent} from './components/schools-add/schools-add.component';
import {SchoolsService} from './services/schools.service';
import {TileChromaComponent} from './components/student-details/tile-chroma/tile-chroma.component';
import {StudentFormsComponent} from './components/student-forms/student-forms.component';
import {ScreeningPerSchoolComponent} from './components/screening-per-school/screening-per-school.component';
import {VisitorPublicformsDetailsComponent} from './components/visitor-publicforms-details/visitor-publicforms-details.component';
import {VisitorPublicformsIndexComponent} from './components/visitor-publicforms-index/visitor-publicforms-index.component';
import {DocumentsFormsHtmlAddComponent} from './components/documents-forms-html-add/documents-forms-html-add.component';
import {BackbuttonSectionComponent} from './components/shared/backbutton-section/backbutton-section.component';
import {ModalTextareaComponent} from './components/shared/modal-textarea/modal-textarea.component';
import {FileUploadLiteComponent} from './components/shared/file-upload-lite/file-upload-lite.component';
import {StudentsSchoolSelectComponent} from './components/students-school-select/students-school-select.component';
import {SchoolMenuComponent} from './components/school-menu/school-menu.component';
import {IdentityService} from './services/identity.service';
import {LoginManualComponent} from './components/login-manual/login-manual.component';
import {CommonSharedService} from "./services_shared/common-shared.service";

// @ts-ignore
@NgModule({
    declarations: [
        AppComponent,
        HomePage,
        StudentsComponent,
        HeaderComponent,
        StudentDetailsComponent,
        LoginComponent,
        CalendarComponent,
        QuickMenuComponent,
        QuickMenuIconComponent,
        LectureSubjectsComponent,
        LectureNewComponent,
        LectureSubjectFileComponent,
        StudentParentsComponent,
        GenderSelectorComponent,
        CallModalComponent,
        PickerDateTimeComponent,
        CalendarEventDetailsComponent,
        SchoolSelectorComponent,
        ClassSelectorComponent,
        PickerDateTimeContainerComponent,
        SchoolSelectorContainerComponent,
        ImageBrowserComponent,
        SubmitSectionComponent,
        FileUploadComponent,
        StudentsImportComponent,
        GenderAvatarComponent,
        StudentDocumentsComponent,
        StudentVbookComponent,
        HomePage,
        VaccineLotsComponent,
        ToastNotificationComponent,
        DocumentsMyComponent,
        ScreeningsComponent,
        SchoolsComponent,
        SchoolMultiSelectorComponent,
        ModalScolioSelectorComponent,
        SingleValueSelectorComponent,
        VaccineMenuComponent,
        DoctorReferenceModalComponent,
        DoctorReferencesComponent,
        ConfirmModalComponent,
        VaccineShotComponent,
        VaccineLotSelectorComponent,
        LectureSubjectNewComponent,
        LectureMenuComponent,
        SmsModalComponent,
        ScreeningMenuComponent,
        DocumentsFormsComponent,
        DocumentsReportsComponent,
        ScreeningAddComponent,
        DocumentsFormsSmsAddComponent,
        ModalActionStudentsComponent,
        ModalVaccineLotsAddComponent,
        CacheTestComponent,
        SettingsComponent,
        ObserveVisibilityDirective,
        ScreeningReportComponent,
        RegistrationComponent,
        UpdateLogComponent,
        ScreeningsMissingComponent,
        SchoolsAddComponent,
        TileChromaComponent,
        StudentFormsComponent,
        ScreeningPerSchoolComponent,
        VisitorPublicformsDetailsComponent,
        VisitorPublicformsIndexComponent,
        DocumentsFormsHtmlAddComponent,
        BackbuttonSectionComponent,
        ModalTextareaComponent,
        FileUploadLiteComponent,
        StudentsSchoolSelectComponent,
        DocumentsMyComponent,
        SchoolMenuComponent,
        LoginManualComponent
    ],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DatePickerModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })

    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    providers: [
        Contacts,
        DatePipe,
        StatusBar,
        SplashScreen,
        Calendar,
        CallNumber,
        Clipboard,
        File,
        FileOpener,
        SMS,
        AndroidPermissions,
        FilePath,
        FileChooser,
        Base64,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        CoreService,
        ScreeningService,
        VisitorService,
        VaccineService,
        ResourcesService,
        LectureService,
        MessageService,
        DocumentsService,
        TranslationsCommonService,
        CachingService,
        SQLite,
        CommonService,
        CalendarService,
        LogService,
        RegistrationService,
        HomepageService,
        SchoolsService,
        IdentityService,
        CommonSharedService
    ],
    exports: [
        QuickMenuIconComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
