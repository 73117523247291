import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {take, map, tap, delay, switchMap} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {HttpService} from './http.service';
import {DatePipe} from '@angular/common';
import {CoreService} from './core.service';
import {url} from 'inspector';
import {StateMessage} from '../models/StateMessage.model';
import {environment} from '../../environment';

@Injectable({
  providedIn: 'root'
})
export class HomepageService {

    constructor(private coreService: CoreService,
                private http: HttpClient,
                private cookieService: CookieService,
                private router: Router,
                private httpService: HttpService,
                private datepipe: DatePipe) {
    }

    getReferencesTileData() {
        console.log('getReferencesTileData');
        return this.httpService.get(environment.URL_ROOT + '/HomeReferrals')
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getReferencesTileDataForSchool(school: string) {
        console.log('getReferencesTileDataForSchool');
        return this.httpService.get(environment.URL_ROOT + '/HomeReferralsPerSchool?school_id=eq.' + school)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }


    getScreeningTileData() {
        console.log('getScreeningTileData');
        return this.httpService.get(environment.URL_ROOT + '/HomeScreenings')
            .pipe(map(r => {
                console.log(r);
                return r;
            },
                err => {
                this.httpService.httpService_stateMessageChanged.emit(new StateMessage('xxx', err.message));
                }));
    }

    getScreeningTileDataForSchool(school: string) {
        console.log('getScreeningTileDataForSchool');
        return this.httpService.get(environment.URL_ROOT + '/HomeScreeningsPerSchool?school_id=eq.' + school)
            .pipe(map(r => {
                    console.log(r);
                    return r;
                },
                err => {
                    this.httpService.httpService_stateMessageChanged.emit(new StateMessage('xxx', err.message));
                }));
    }


    getStudentsTileDataForSchool(school: string) {
        console.log('getStudentsTileDataForSchool');
        return this.httpService.get(environment.URL_ROOT + '/HomeStudentListPerSchool?school_id=eq.' + school)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getStudentsTileData() {
        console.log('getStudentsTileData');
        return this.httpService.get(environment.URL_ROOT + '/HomeStudentList')
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getCalendarTileData() {
        console.log('getCalendarTileData');
        return this.httpService.get(environment.URL_ROOT + '/HomeCalendar')
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    getSchoolsTileData() {
        console.log('getSchoolsTileData');
        return this.httpService.get(environment.URL_ROOT + '/HomeSchools')
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }
}
