import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {ModalController} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {TranslateService} from '@ngx-translate/core';
import {ScreeningService} from '../../services/screening.service';
import {PickerDateTimeComponent} from '../shared/picker-date-time/picker-date-time.component';
import {ModalScolioSelectorComponent} from '../shared/modal-scolio-selector/modal-scolio-selector.component';
import {Subscription} from 'rxjs';
import {SingleValueSelectorComponent} from '../shared/single-value-selector/single-value-selector.component';
import {DoctorReferenceModalComponent} from '../shared/doctor-reference-modal/doctor-reference-modal.component';
import {ConfirmModalComponent} from '../shared/confirm-modal/confirm-modal.component';
import {TranslationsCommonService} from '../../services/translations.common.service';
import {Location} from '@angular/common';
import {CallModalComponent} from '../shared/call-modal/call-modal.component';
import {ImageBrowserComponent} from '../shared/image-browser/image-browser.component';
import {TileTypeVisibility} from '../shared/tiletestvisibility.model';
import {newArray} from '@angular/compiler/src/util';

@Component({
  selector: 'app-student-details',
  templateUrl: './student-details.component.html',
  styleUrls: ['./student-details.component.scss'],
})
export class StudentDetailsComponent implements OnInit, OnDestroy {
  FORMS_ALL = [];
  FORMS_TOTAL = 0;
  FORMS_COMPLETED = 0;
  FORMS_PENDING = 0;

  parents = [];
  studentFiles = [];
  vaccines = [];

  message = '';
  studentId = '';
  isLoading = false;
  studentData = null;
  testData = null;

  references = [];

  vision_test_submit_name = 'vision_test';
  visionTestFormVisible = false;
  test_vision_eye_left_valid = true;
  test_vision_eye_left_name = 'test_vision_eye_left';
  test_vision_eye_left_name_display = 'Left eye row';
  test_vision_eye_left = '';


  test_vision_eye_right_valid = true;
  test_vision_eye_right_name = 'test_vision_eye_right';
  test_vision_eye_right_name_display = 'Right eye row';
  test_vision_eye_right = '';
  test_vision_notes = '';
  test_vision_glasses = false;
  test_vision_doctor = false;

  audio_test_submit_name = 'audio_test';
  audioTestFormVisible = false;

  test_audio_ear_left_db_valid = true;
  test_audio_ear_left_db_name = 'test_audio_ear_left_db';
  test_audio_ear_left_db_name_display = 'Left ear dB';
  test_audio_ear_left_db = '';

  test_audio_ear_right_db_valid = true;
  test_audio_ear_right_db_name = 'test_audio_ear_right_db';
  test_audio_ear_right_db_name_display = 'Right ear dB';
  test_audio_ear_right_db = '';

  test_audio_ear_left_hz_valid = true;
  test_audio_ear_left_hz_name = 'test_audio_ear_left_hz';
  test_audio_ear_left_hz_name_display = 'Left ear Hz';
  test_audio_ear_left_hz = '';

  test_audio_ear_right_hz_valid = true;
  test_audio_ear_right_hz_name = 'test_audio_ear_right_hz';
  test_audio_ear_right_hz_name_display = 'Right ear Hz';
  test_audio_ear_right_hz = '';
  test_audio_doctor = false;
  test_audio_notes = '';

  scolio_test_submit_name = 'scolio_test';
  scolioTestFormVisible = false;
  test_scolio_neck_deg = '0';
  test_scolio_neck_dir = '';
  test_scolio_chest_deg = '0';
  test_scolio_chest_dir = '';
  test_scolio_lumb_deg = '0';
  test_scolio_lumb_dir = '';
  test_scolio_notes = '';
  test_scolio_doctor = false;

  bmi_submit_name = 'bmi_test';
  bmiTestFormVisible = false;
  bmi = '';
  bmi_notes = '';

  bmi_weight_valid = true;
  bmi_weight_name = 'weight';
  bmi_weight_name_display = 'Weight';
  bmi_weight = '';

  bmi_height_valid = true;
  bmi_height_name = 'height';
  bmi_height_name_display = 'Height';
  bmi_height = '';

  chroma_test_submit_name = 'chroma_test';
  chromaTestFormVisible = false;
  test_chroma_notes = '';
  test_chroma_doctor = false;

  TRNSL_MESSAGE_CONFIRM_DELETE_STUDENT = '';
  delete_section_name = 'delete_student_NONE';

  scolioSettingsChangedSub: Subscription;
  submitSectionSub: Subscription;
  singleSettingValueChangedSub: Subscription;
  doctorReferenceCreated: Subscription;
  tileVisibilityChanged: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private coreService: CoreService,
              private modalCtrl: ModalController,
              private httpService: HttpService,
              private screeningService: ScreeningService,
              private translate: TranslateService,
              private location: Location,
              private translatationsCommonService: TranslationsCommonService) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });
  }

  ngOnDestroy() {
    if (this.scolioSettingsChangedSub) {
      this.scolioSettingsChangedSub.unsubscribe();
    }

    if (this.submitSectionSub) {
      this.submitSectionSub.unsubscribe();
    }

    if (this.singleSettingValueChangedSub) {
        this.singleSettingValueChangedSub.unsubscribe();
    }

    if (this.doctorReferenceCreated) {
        this.doctorReferenceCreated.unsubscribe();
    }

    if (this.submitSectionSub) {
        this.submitSectionSub.unsubscribe();
    }

    if (this.tileVisibilityChanged) {
        this.tileVisibilityChanged.unsubscribe();
    }
  }


  ngOnInit() {
     if (!this.httpService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
     }

     this.studentId = this.route.snapshot.paramMap.get('id');
     this.delete_section_name = 'delete_student_' + this.studentId;

     console.log('onLoadStudents');
     this.isLoading = true;

     this.coreService.getStudent(this.studentId).subscribe(r => {
        console.log('STUDENT DATA');
        console.log(r);
        this.studentData = r[0];
        this.isLoading = false;

     }, err => {
        console.log(err);
        this.coreService.broadcastErrorMessage('Fetching getStudent failed ', err);
        this.isLoading = false;
     });

     this.loadTestResults();
     this.loadReferences();

     this.loadParents();
     this.loadFiles();
     this.loadReferences();
     this.loadVBook();
     this.getHomeStudentForms();

     this.tileVisibilityChanged = this.coreService.tileVisibilityChanged.subscribe( (x: TileTypeVisibility) => {
         switch (x.tileType) {
             case 'CHROMA': this.chromaTestFormVisible = x.visible; break;
             case 'VISION': this.visionTestFormVisible = x.visible; break;
         }
     });

     this.singleSettingValueChangedSub = this.coreService.singleSettingValueChanged.subscribe(newSettingValue => {

      switch (newSettingValue.settingName){
        case this.test_vision_eye_left_name:
          this.test_vision_eye_left = newSettingValue.settingValue;
          break;
        case this.test_vision_eye_right_name:
          this.test_vision_eye_right = newSettingValue.settingValue;
          break;
        case this.test_audio_ear_left_db_name:
          this.test_audio_ear_left_db = newSettingValue.settingValue;
          break;
        case this.test_audio_ear_right_db_name:
          this.test_audio_ear_right_db = newSettingValue.settingValue;
          break;
        case this.test_audio_ear_left_hz_name:
          this.test_audio_ear_left_hz = newSettingValue.settingValue;
          break;
        case this.test_audio_ear_right_hz_name:
          this.test_audio_ear_right_hz = newSettingValue.settingValue;
          break;
        case this.bmi_weight_name:
          this.bmi_weight = newSettingValue.settingValue;
          break;
        case this.bmi_height_name:
          this.bmi_height = newSettingValue.settingValue;
          break;
      }
    });

     this.scolioSettingsChangedSub = this.screeningService.scolioSettingsChanged.subscribe(newScolioSettings => {
      console.log('student-details.scolioSettingsChangedSub:');
      console.log(newScolioSettings);
      switch (newScolioSettings.part){
        case 'neck':
          this.test_scolio_neck_deg = newScolioSettings.deg;
          this.test_scolio_neck_dir = newScolioSettings.dir;
          break;
        case 'chest':
          this.test_scolio_chest_deg = newScolioSettings.deg;
          this.test_scolio_chest_dir = newScolioSettings.dir;
          break;
        case 'lumb':
          this.test_scolio_lumb_deg = newScolioSettings.deg;
          this.test_scolio_lumb_dir = newScolioSettings.dir;
          break;
      }
    });

     this.doctorReferenceCreated = this.screeningService.doctorReferenceCreated.subscribe(student => {
       if (student === this.studentId) {
         this.loadReferences();
       }
     });

     this.submitSectionSub = this.coreService.submitSectionsChanged.subscribe(
        submitSectionDataItems  => {
          console.log('submitSectionSub: ');
          console.log(submitSectionDataItems);

          submitSectionDataItems.map(i => {
            console.log('i:', i);
            console.log('sectionName: ', i.sectionName);

            switch (i.sectionName) {

              case this.delete_section_name:
                console.log('DELETING STUDENT...');
                if (i.submitBroadcasted) {
                  this.router.navigateByUrl('/');
                }
                break;

              case this.scolio_test_submit_name:
                if (i.cancelBroadcasted === true) {
                  this.scolioTestFormVisible = false;
                  this.loadTestResults();
                }

                if (i.submitBroadcasted === true) {
                  this.saveScolioTest();
                  this.test_scolio_notes = '';
                  this.test_scolio_doctor = false;
                }
                break;

              case this.audio_test_submit_name:
                if (i.cancelBroadcasted === true) {
                  this.audioTestFormVisible = false;

                  this.loadTestResults();
                }

                if (i.submitBroadcasted === true) {
                  this.saveAudioTest();
                  this.test_audio_notes = '';
                  this.test_audio_doctor = false;
                }
                break;

              case this.vision_test_submit_name:
                if (i.cancelBroadcasted === true) {
                  this.visionTestFormVisible = false;

                  this.loadTestResults();
                }

                if (i.submitBroadcasted === true) {
                  this.saveVisionTest();
                  this.test_vision_notes = '';
                  this.test_vision_doctor = false;
                }
                break;

              case this.bmi_submit_name:
                if (i.cancelBroadcasted === true) {
                  this.bmiTestFormVisible = false;

                  this.loadTestResults();
                }

                if (i.submitBroadcasted === true) {
                  this.saveBmi();
                }
                break;
              case this.chroma_test_submit_name:
                if (i.cancelBroadcasted === true) {
                  this.chromaTestFormVisible = false;

                  this.loadTestResults();
                }

                if (i.submitBroadcasted === true) {
                  this.saveChroma();
                }
                break;
            }
          });
        }
    );
  }

    openImageBrowser(fileId: string) {
        this.modalCtrl
            .create({
                component: ImageBrowserComponent,
                componentProps: { fileId, section: 'student' },
                cssClass: 'bg-none'
            })
            .then(modalEl => {
                modalEl.present();
                return modalEl.onDidDismiss();
            });
    }

    loadFiles() {
        this.coreService.getStudentFiles(this.studentId).subscribe(r => {
            this.studentFiles = r.reverse().slice(-3);
        });
    }

    loadParents() {
        this.coreService.getParents(this.studentId).subscribe((r: any[]) => {
            console.log('loadParents');
            console.log(r[0]);
            this.isLoading = false;
            this.parents = r.reverse().slice(-3);
        });
    }

    getHomeStudentForms() {
        this.coreService.getHomeStudentForms(this.studentId).subscribe((r: any[]) => {
            console.log('getHomeStudentForms: ' + this.studentId);
            this.FORMS_ALL = r;
            this.FORMS_TOTAL = r.length;
            this.FORMS_COMPLETED = r.filter(x => x.completed === true).length;
            this.FORMS_PENDING = r.filter(x => x.completed === false).length;

        });
    }

    loadReferences() {
        this.screeningService.getStudentsReferences(this.studentId).subscribe((r) => {
            console.log('student-details: loadReferences, current studentId: ', this.studentId);
            console.log(r);
            this.isLoading = false;
       //     this.references = r.slice(-4);
            this.references = r; //.slice(-4);
        }, err => {
            console.log(err);
            this.coreService.broadcastErrorMessage('Fetching test results failed ', err);
            this.isLoading = false;
        });
    }



    loadVBook(){
        this.coreService.getStudentVBookNEW(this.studentId, false).subscribe(r => {
            console.log(r);
            this.isLoading = false;
            this.vaccines = r.slice(-4);
        }, err => {
            console.log(err);
            //this.coreService.broadcastErrorMessage('Fetching student vbook failed ', err);
            this.isLoading = false;
        });
    }

    openExistingDoctorReference(TEST_TYPE: string, referenceId: string) {

        this.modalCtrl
            .create({
                component: DoctorReferenceModalComponent,
                componentProps: { student: this.studentId, testType: TEST_TYPE, referenceId: referenceId, createMode: false }
            })
            .then(modalEl => {
                modalEl.present();
                return modalEl.onDidDismiss();
            });
    }

    showCallOptions(phoneNumber: string, firstName: string, lastName: string) {
        console.log('showCallOptions', phoneNumber);

        this.modalCtrl
            .create({
                component: CallModalComponent,
                componentProps: { phoneNumber, firstName, lastName }
            })
            .then(modalEl => {
                modalEl.present();
                return modalEl.onDidDismiss();
            });
    }

    initiateStudentDelete(TEST_TYPE: string) {

        this.modalCtrl
            .create({
                component: ConfirmModalComponent,
                componentProps: { message: this.translatationsCommonService.TRNSL_MESSAGE_CONFIRM_DELETE_STUDENT, sectionName: this.delete_section_name }
            })
            .then(modalEl => {
                modalEl.present();
                return modalEl.onDidDismiss();
            });
    }

  displayScolioValues(part: string) {
    // console.log('displayScolioValues, part: ', part);
    // console.log('test_scolio_lumb_deg: ', this.test_scolio_lumb_deg);
    // console.log('test_scolio_lumb_dir: ', this.test_scolio_lumb_dir);

    let deg = '';
    let dir = '';

    switch (part) {
      case 'neck':
        deg = this.test_scolio_neck_deg;
        dir = this.test_scolio_neck_dir;
        break;
      case 'chest':
        deg = this.test_scolio_chest_deg;
        dir = this.test_scolio_chest_dir;
        break;
      case 'lumb':
        deg = this.test_scolio_lumb_deg;
        dir = this.test_scolio_lumb_dir;
        break;
    }

    let result = '';

    if (dir === '') {
      result =   deg + '°';
    } else {
      result = dir + ': ' + deg + '°';
    }

    result = result.replace('null', '-').replace('null', '-');
    return result;
  }

  initiateDoctorReference(TEST_TYPE: string, referenceId: string, test: any = null) {

      console.log('TEST ID: ' + test);
      this.modalCtrl
          .create({
            component: DoctorReferenceModalComponent,
            componentProps: { student: this.studentId, testType: TEST_TYPE, referenceId, testId: test, createMode: true }
          })
          .then(modalEl => {
            modalEl.present();
            return modalEl.onDidDismiss();
          });
  }




     sortByTimestampDesc(arr: any) {
        return arr.sort((a: any, b: any) => {
            return (new Date(b.timestamp) as any) - (new Date(a.timestamp) as any);
        });
     }

  loadTestResults() {
      this.screeningService.getStudentsTestResult(this.studentId).subscribe((r) => {
      console.log('getStudentsTestResult');
      console.log(r);
      this.isLoading = false;

      this.testData = r.results;



      console.log('ALL TEST RESULTS:');
      console.log(this.testData);

      if (r.results != null){

          if(r.results.references != null){
              this.references = r.results.references;
              console.log('references: ');
              console.log(this.references);
          }




          // CHROMA
          if (this.testData.chroma.results.length > 0) {
              const sortedChroma = this.testData.chroma.results; // this.sortByTimestampDesc(this.testData.chroma.results); ////
              console.log(sortedChroma);
              this.test_chroma_doctor = sortedChroma[0].reference;
          } else {
              this.test_vision_eye_left = '';
              this.test_vision_eye_right = '';
              this.test_chroma_doctor = null;
          }



          // VISION
          if (this.testData.vision.results.length > 0) {
              const sortedVision = this.testData.vision.results; //this.sortByTimestampDesc(this.testData.vision.results);
              console.log(sortedVision);
              this.test_vision_eye_left = sortedVision[0].left_eye_row;
              this.test_vision_eye_right = sortedVision[0].right_eye_row;
              this.test_vision_doctor = sortedVision[0].reference;
              this.test_vision_notes = sortedVision[0].notes;
          } else {
              this.test_vision_eye_left = '';
              this.test_vision_eye_right = '';
              this.test_vision_doctor = null;
              this.test_vision_notes = '';
          }



          // AUDIO
          if (this.testData.audio.results.length > 0) {
              const sortedAudio = this.testData.audio.results; // this.sortByTimestampDesc(this.testData.audio.results);
              console.log(sortedAudio);
              this.test_audio_ear_left_db = sortedAudio[0].left_ear_db;
              this.test_audio_ear_right_db = sortedAudio[0].right_ear_db;
              this.test_audio_ear_left_hz = sortedAudio[0].left_ear_hz;
              this.test_audio_ear_right_hz = sortedAudio[0].right_ear_hz;
              this.test_audio_doctor = sortedAudio[0].reference;
              this.test_audio_notes = sortedAudio[0].notes;
          } else {
              this.test_audio_ear_left_db = '';
              this.test_audio_ear_right_db = '';
              this.test_audio_ear_left_hz =  '';
              this.test_audio_ear_right_hz = '';
              this.test_audio_doctor = null;
              this.test_audio_notes = '';
          }




          // SCOLIO
          if (this.testData.scolio.results.length > 0) {
              const sortedScolio = this.testData.scolio.results; // this.sortByTimestampDesc(this.testData.scolio.results);
              console.log(sortedScolio);
              this.test_scolio_neck_deg = sortedScolio[0].neck_deg;
              this.test_scolio_neck_dir = sortedScolio[0].neck_dir;
              this.test_scolio_chest_deg = sortedScolio[0].chest_deg;
              this.test_scolio_chest_dir = sortedScolio[0].chest_dir;
              this.test_scolio_lumb_deg = sortedScolio[0].lumb_deg;
              this.test_scolio_lumb_dir = sortedScolio[0].lumb_dir;
              this.test_scolio_doctor = sortedScolio[0].reference;
              this.test_scolio_notes = sortedScolio[0].notes;
          } else {
              this.test_scolio_neck_deg = '0';
              this.test_scolio_chest_deg = '0';
              this.test_scolio_lumb_deg = '0';
              this.test_scolio_notes = '';
          }


          // BMI
          if (this.testData.bmi.results.length > 0) {
              const sortedBmi = this.testData.bmi.results; // this.sortByTimestampDesc(this.testData.bmi.results);
              console.log(sortedBmi);
              this.bmi = sortedBmi[0].bmi;
              this.bmi_weight = sortedBmi[0].weight;
              this.bmi_height = sortedBmi[0].height;
              this.bmi_notes = sortedBmi[0].notes;
          } else {
              this.bmi = '';
              this.bmi_weight = '';
              this.bmi_height = '';
              this.bmi_notes = '';
          }

          console.log('updated bmi_weight: ' + this.bmi_weight);
          console.log('updated bmi_height: ' + this.bmi_height);


      }







          // // CHROMA
          // if (this.testData.chroma.results.length > 0) {
          //     const sortedChroma = this.testData.chroma.results; // this.sortByTimestampDesc(this.testData.chroma.results);
          //     console.log(sortedChroma);
          //     this.test_chroma_notes = sortedChroma[0].notes;
          // } else {
          //     this.test_chroma_notes = '';
          // }

    }, err => {
      console.log(err);
      //this.coreService.broadcastErrorMessage('Fetching test results failed ', err);
      this.isLoading = false;
    });
  }



  saveVisionTest() {
    console.log('saveVistionTest');

    if (this.visionTestValid()){
      console.log('saving vision test');

      this.screeningService.screening_addVision(
          this.studentId,
          this.test_vision_eye_left.toString(),
          this.test_vision_eye_right,
          this.test_vision_notes,
          this.test_vision_glasses
      ).subscribe((r: any[]) => {
        console.log('saveAudioTest');
        console.log(r);
        this.isLoading = false;

        this.visionTestFormVisible = false;
        this.coreService.broadcastToastMessage(this.translatationsCommonService.TRNSL_SCREENING_MESSAGE_SAVE_SUCCESS, false);

        // broadcast student update - so appropriate listeners can update test color icons on students list
        this.coreService.broadcastStudentUpdate(this.studentId);

        console.log('MMM.loadTestResults()');
        this.loadTestResults();
      }, err => {
        console.log(err);
        //this.coreService.broadcastErrorMessage(this.translatationsCommonService.TRNSL_SCREENING_MESSAGE_SAVE_FAIL, err);
        this.isLoading = false;
      });

    } else {
        console.log('vision test invalid');
        //this.coreService.broadcastToastMessage(this.translatationsCommonService.TRNSL_SCREENING_MESSAGE_FIX_DATA, true);
    }
  }

  saveChroma() {
    console.log('saveChroma');
    console.log('saving chroma test');

    this.screeningService.screening_addChroma(
        this.studentId,
        this.test_chroma_notes.toString(),
        this.test_chroma_doctor
    ).subscribe((r: any) => {
      console.log('savedChroma');
      console.log(r);
      this.isLoading = false;

      this.chromaTestFormVisible = false;
      this.coreService.broadcastToastMessage(this.translatationsCommonService.TRNSL_SCREENING_MESSAGE_SAVE_SUCCESS, false);

      // broadcast student update - so appropriate listeners can update test color icons on students list
      this.coreService.broadcastStudentUpdate(this.studentId);

      this.loadTestResults();
    }, err => {
      console.log(err);
      //this.coreService.broadcastErrorMessage(this.translatationsCommonService.TRNSL_SCREENING_MESSAGE_SAVE_FAIL, err);
      this.isLoading = false;
    });
  }


  saveBmi() {
      console.log('saveBmi');
      console.log('saving bmi test');

      this.screeningService.screening_addBmi(
          this.studentId,
          this.bmi_weight.toString(),
          this.bmi_height,
          this.bmi_notes
      ).subscribe((r: any) => {
        console.log('savedBmi');
        console.log(r);
        this.isLoading = false;

        this.bmiTestFormVisible = false;
        this.coreService.broadcastToastMessage(this.translatationsCommonService.TRNSL_SCREENING_MESSAGE_SAVE_SUCCESS, false);

        // broadcast student update - so appropriate listeners can update test color icons on students list
        this.coreService.broadcastStudentUpdate(this.studentId);

        this.loadTestResults();
      }, err => {
        console.log(err);
        //this.coreService.broadcastErrorMessage(this.translatationsCommonService.TRNSL_SCREENING_MESSAGE_SAVE_FAIL, err);
        this.isLoading = false;
      });
  }


  visionTestValid() {
    const testValid =  this.test_vision_eye_left_valid  && this.test_vision_eye_right_valid;
    return testValid;
  }


  audioTestValid() {
    const testValid =  this.test_audio_ear_left_db_valid  && this.test_audio_ear_right_db_valid && this.test_audio_ear_left_hz_valid && this.test_audio_ear_right_hz_valid ;
    return testValid;
  }

  saveAudioTest() {
    console.log('saveAudioTest');

    if (this.audioTestValid()){
      console.log('saving vision test');

      this.screeningService.screening_addAudio(
          this.studentId,
          this.test_audio_ear_left_hz,
          this.test_audio_ear_right_hz,
          this.test_audio_ear_left_db,
          this.test_audio_ear_right_db,
          this.test_audio_notes,
          this.test_audio_doctor,
      ).subscribe((r: any[]) => {
        console.log('saveAudioTest');
        console.log(r);
        this.isLoading = false;

        this.audioTestFormVisible = false;
        this.coreService.broadcastToastMessage(this.translatationsCommonService.TRNSL_SCREENING_MESSAGE_SAVE_SUCCESS, false);

        // broadcast student update - so appropriate listeners can update test color icons on students list
        this.coreService.broadcastStudentUpdate(this.studentId);

        this.loadTestResults();
      }, err => {
        console.log(err);
        //this.coreService.broadcastErrorMessage(this.translatationsCommonService.TRNSL_SCREENING_MESSAGE_SAVE_FAIL, err);
        this.isLoading = false;
      });
    } else {
      console.log('audio test invalid');
      this.coreService.broadcastToastMessage(this.translatationsCommonService.TRNSL_SCREENING_MESSAGE_FIX_DATA, true);
    }
  }

  chromaTestValid() {
    return true;
  }

  scolioTestValid() {
    return true;
  }

  saveScolioTest() {
    console.log('saveScolioTest');

    this.screeningService.screening_addScolio(
          this.studentId,
          this.test_scolio_doctor,
          this.test_scolio_neck_dir,
          this.test_scolio_neck_deg,
          this.test_scolio_chest_dir,
          this.test_scolio_chest_deg,
          this.test_scolio_lumb_dir,
          this.test_scolio_lumb_deg,
          this.test_scolio_notes
          ).subscribe((r: any[]) => {
        console.log('screening_addScolio');
        console.log(r);
        this.isLoading = false;

        this.test_scolio_notes = '';
        this.test_scolio_doctor = false;

        this.scolioTestFormVisible = false;
        this.coreService.broadcastToastMessage(this.translatationsCommonService.TRNSL_SCREENING_MESSAGE_SAVE_SUCCESS, false);

      // broadcast student update - so appropriate listeners can update test color icons on students list
      this.coreService.broadcastStudentUpdate(this.studentId);

        this.loadTestResults();
      }, err => {
        console.log(err);
        //this.coreService.broadcastErrorMessage(this.translatationsCommonService.TRNSL_SCREENING_MESSAGE_SAVE_FAIL, err);
        this.isLoading = false;
      });
  }

  loadScolioSelector(partName: string){

    if (this.scolioTestFormVisible) {
      this.modalCtrl
          .create({
            component: ModalScolioSelectorComponent,
            componentProps: {   rangeMin: 1, rangeMax: 30, part:  partName  },
            cssClass: 'bg-none'
          })
          .then(modalEl => {
            modalEl.present();
            return modalEl.onDidDismiss();
          });
    }
  }

  getDoctorClass() {
    if (this.references.length === 0) {
       return 'coreMenuIconWhite';
    } else {
      let hasRed = false;
      let hasOrange = false;
      let greenCount = 0;
      let whiteCount = 0;

      this.references.map(i => {
        if (i.color === this.screeningService.DOCTOR_REFERENCE_COLORS.GREEN) {
          greenCount ++;
        }

        if (i.color === this.screeningService.DOCTOR_REFERENCE_COLORS.WHITE) {
          whiteCount ++;
        }

        if (i.color === this.screeningService.DOCTOR_REFERENCE_COLORS.RED) {
          hasRed = true;
        }

        if (i.color === this.screeningService.DOCTOR_REFERENCE_COLORS.ORANGE) {
          hasOrange = true;
        }
      });

//      console.log('getSuitcaseColor: hasRed: ' + hasRed + ', hasOrange:' + hasOrange  + ', greenCount:' + greenCount + ', whiteCount: ' + whiteCount);

      if (greenCount === this.references.length) {
        return 'coreMenuIconGreen';
      } else {
        if (hasRed) {
          return 'coreMenuIconRed';
        } else if (hasOrange){
          return 'coreMenuIconOrange';
        } else if (whiteCount === this.references.length) {
          return 'coreMenuIconWhite';
        }
      }
    }
  }

    getTilesGridClass() {
        let classToUse = 'bluredSectionStudent';
        if (!this.scolioTestFormVisible &&
            !this.bmiTestFormVisible &&
            !this.visionTestFormVisible &&
            !this.audioTestFormVisible &&
            !this.chromaTestFormVisible) {

            //            classToUse = 'core-test';
            classToUse = '';
        }

        return classToUse;
    }

  getTestBackgroundClass(currentForm: string, isAlternate: boolean = false) {
    let classToUse = 'bluredSectionStudent';
    if (!this.scolioTestFormVisible &&
        !this.bmiTestFormVisible &&
        !this.visionTestFormVisible &&
        !this.audioTestFormVisible &&
        !this.chromaTestFormVisible) {

      classToUse = 'core-test';

    } else {

      switch (currentForm) {
        case 'scolioTestForm': if (this.scolioTestFormVisible) { classToUse = 'bgActive centered'; } break;
        case 'visionTestForm': if (this.visionTestFormVisible) { classToUse = 'bgActive centered'; } break;
        case 'audioTestForm': if (this.audioTestFormVisible) { classToUse = 'bgActive centered'; } break;
        case 'chromaTestForm': if (this.chromaTestFormVisible) { classToUse = 'bgActive centered'; } break;
        case 'bmiTestForm': if (this.bmiTestFormVisible) { classToUse = 'bgActive centered'; } break;
        default: classToUse = 'bluredSectionStudent'; break;
      }
    }

    return classToUse;
  }

  loadSingleValueSelector(singleValueName: string, displayName: string, dataType: string, lengthRequired: number){
    console.log('loadSingleValueSelector: ', singleValueName);
    switch (singleValueName){
      case this.test_vision_eye_left_name:
      case this.test_vision_eye_right_name:
        if (this.visionTestFormVisible) {
          this.modalCtrl
              .create({
                component: SingleValueSelectorComponent,
                componentProps: {   settingName: singleValueName, displayName, dataType, lengthRequired  },
                cssClass: 'bg-none'
              })
              .then(modalEl => {
                modalEl.present();
                return modalEl.onDidDismiss();
              });
        }
        break;

      case this.test_audio_ear_left_db_name:
      case this.test_audio_ear_right_db_name:
      case this.test_audio_ear_left_hz_name:
      case this.test_audio_ear_right_hz_name:
        if (this.audioTestFormVisible) {
          this.modalCtrl
              .create({
                component: SingleValueSelectorComponent,
                componentProps: {   settingName: singleValueName, displayName, dataType, lengthRequired  },
                cssClass: 'bg-none'
              })
              .then(modalEl => {
                modalEl.present();
                return modalEl.onDidDismiss();
              });
        }
        break;
      case this.bmi_weight_name:
      case this.bmi_height_name:
        if (this.bmiTestFormVisible) {
          this.modalCtrl
              .create({
                component: SingleValueSelectorComponent,
                componentProps: {   settingName: singleValueName, displayName, dataType, lengthRequired  },
                cssClass: 'bg-none'
              })
              .then(modalEl => {
                modalEl.present();
                return modalEl.onDidDismiss();
              });
        }
        break;
    }
  }

  getTileVisivility_References() {
      if(this.references != null){
          if(this.references.length > 0) {
              return true;
          } else {
              return false;
          }
      }else {
          return false;
      }
  }


}
