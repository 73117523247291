import { Injectable } from '@angular/core';
import {environment} from '../../environment';
import {HttpService} from './http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NavController} from '@ionic/angular';
import {CoreService} from './core.service';
import {DomSanitizer} from '@angular/platform-browser';
import {Location} from '@angular/common';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class IdentityService {

    constructor(
        private route: ActivatedRoute,
        public router: Router,
        private navCtrl: NavController,
        private sanitizer: DomSanitizer,
        private httpService: HttpService,
        private location: Location
    ) {
    }

    build_IdPortalUrl(relativeRedirectUrl_WITHOUT_leadingForSlash: string) {

        const loginPortalUrl = environment.LOGIN_PORTAL_URL;
        const ourPortalUrlBase64 = btoa(environment.WEB_APP_URL);
        const absoluteRedirectUrl = environment.WEB_APP_URL + '/' + relativeRedirectUrl_WITHOUT_leadingForSlash;
        const absoluteRedirectUrlBase64 =  btoa(absoluteRedirectUrl);

        // {loginportalUrl}/{ourPortalUrlBase64}/{absoluteRedirectUrlBase64}/{dbVector}
        const LOGIN_URL = loginPortalUrl + '/login/' + encodeURIComponent(ourPortalUrlBase64) + '/' + encodeURIComponent(absoluteRedirectUrlBase64) + '/' + environment.DB_USER_GROUP;

        return LOGIN_URL;
    }

    build_profileUrl(){
        const returnUrlBase64 = this.build_ReturnUrlBase64(environment.WEB_APP_URL);
        const url = environment.LOGIN_PORTAL_URL + '/profile/' + environment.DB_USER_GROUP + '/' + returnUrlBase64;

        return url;
    }

    build_SetLangUrl(returnUrl: string, lang: string){
        const returnUrlBase64 = this.build_ReturnUrlBase64(returnUrl);
        const url = environment.LOGIN_PORTAL_URL + '/setlang/' + environment.DB_USER_GROUP + '/' + lang + '/' + returnUrlBase64;

        return url;
    }

    build_ReturnUrlBase64(url: string){

        return encodeURIComponent(btoa(url));
        //   aHR0cHM6Ly9zY29vbG1lZC5jb20=
    }

    loadAvailableLanguages() {
        console.log('loadAvailableLanguages');

        return this.httpService.get (environment.AUTH_ROOT_URL + '/GetLanguages' , null)
            .pipe(
                map(r => {
                    return r;
                })
            );
    }

    profile() {
        console.log('profile');

        return this.httpService.get (environment.URL_ROOT + '/Profile' , null)
            .pipe(
                map(r => {
                    console.log(r);
                    return r;
                })
            );
    }


}
