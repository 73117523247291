import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {LoadingController, ModalController} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {SchoolClass} from '../shared/schoolclass.model';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe, Location} from '@angular/common';
import { ToastController } from '@ionic/angular';
import {DatePickerData} from '../shared/datepickerdata.model';
import {CalendarService} from '../../services/calendar.service';
import {IdentityService} from '../../services/identity.service';

@Component({
  selector: 'app-lecture-new',
  templateUrl: './lecture-new.component.html',
  styleUrls: ['./lecture-new.component.scss'],
})
export class LectureNewComponent implements OnInit, OnDestroy {
    operationCompleted = false;
    subjectId = '';
    subjectIdFromForm = '';

    formName = 'addLecture' + this.coreService.generateFormNamePostfix();

    startDt1 = this.datepipe.transform(new Date(new Date().getTime() + 1440 * 60000) , this.coreService.DATETIME_FORMAT);   // +24h
    endDt1 = this.datepipe.transform(new Date(new Date().getTime() + 1485 * 60000) , this.coreService.DATETIME_FORMAT); // + 24h, 45min

    startDt = this.datepipe.transform(new Date(new Date(this.startDt1).getTime() ) , this.coreService.DATE_FORMAT) + " 08:00";   // +24h
    endDt = this.datepipe.transform(new Date(new Date(this.endDt1).getTime() ) , this.coreService.DATE_FORMAT) + " 08:45"; // + 24h, 45min
    durationNegative = false;
    duration = '45';

    notes = '';

    initialDateStart = this.datepipe.transform(new Date() , this.coreService.DATETIME_FORMAT);
    initialDateEnd = this.datepipe.transform(new Date(new Date().getTime() + 45 * 60000) , this.coreService.DATETIME_FORMAT);

    profileData;

  private submitSectionSub: Subscription;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private coreService: CoreService,
              private modalCtrl: ModalController,
              private httpService: HttpService,
              private translate: TranslateService,
              private datepipe: DatePipe,
              private location: Location,
              public identityService: IdentityService,
              public toastController: ToastController,
              private calendarService: CalendarService) {

      // location.onUrlChange((url, state) => {
      //     this.ngOnInit();
      // });
  }

  form: FormGroup;
  private selectedClassesSub: Subscription;
  private datePickerDatesChangedSub: Subscription;

  selectedDate = '';

  selectedClasses = [];
  isLoading = false;
  subjects = [];

  ngOnDestroy() {
    if (this.submitSectionSub) {
          this.submitSectionSub.unsubscribe();
    }

    if (this.selectedClassesSub) {
      this.selectedClassesSub.unsubscribe();
    }

    if (this.datePickerDatesChangedSub) {
      this.datePickerDatesChangedSub.unsubscribe();
    }
  }

  ngOnInit() {

    if (!this.httpService.isLoggedIn()){
        this.router.navigateByUrl('/login');
    }

    this.subjectId = this.route.snapshot.paramMap.get('subjectId');
    this.subjectIdFromForm = this.subjectId;

    // this.form = new FormGroup({
    //
    //   notes: new FormControl(null, {
    //     updateOn: 'blur',
    //     validators: [ ]
    //   })
    // });

      this.identityService.profile().subscribe(x => {
          this.profileData = x[0];
      });

    this.isLoading = true;
    this.coreService.getSubjects().subscribe((r: any[]) => {

    console.log('SUBJECT ID:');
    console.log(this.subjectId);
    if (this.subjectId !== '' && this.subjectId !== null) {
      const newSubjects = [];

      this.subjects = r.map(x => {
        if (x.subject.toString() === this.subjectId) {
          newSubjects.push(x);
        }
      });

      console.log('newSubjects');
      console.log(newSubjects);
      console.log('subjectId: ' + this.subjectId);

      this.subjects = newSubjects.slice();
    } else {
      this.subjects = r;
    }

    this.isLoading = false;
  });

    this.selectedClassesSub = this.coreService.classesSelectedChanged
        .subscribe(
            (classes: SchoolClass[]) => {
              console.log('classesSelectedChanged: ');
              console.log(classes);

              this.selectedClasses = [];
              classes.map(x => {
                this.selectedClasses.push(x.class_code);
              });
            }
        );

      this.datePickerDatesChangedSub = this.coreService.datePickerDatesChanged
          .subscribe(
              dates  => {
                  console.log('datePickerDatesChangedSub: ');
                  console.log(dates);

                  const itemStartDt = dates.find(x => x.descriptionLabel === 'Start Date');

                  if (itemStartDt != null){
                      this.startDt = itemStartDt.date;
                  }

                  const itemEndDate = dates.find(x => x.descriptionLabel === 'End Date');
                  if (itemEndDate != null){
                      this.endDt = itemEndDate.date;
                  }

                  console.log('calculating duration');
                  let dif = (+(new Date(this.endDt)) - +(new Date(this.startDt)));
                  dif = Math.round((dif / 1000 ) / 60);

                  console.log(dif);

                  if (+dif < 0) {
                      this.durationNegative = true;
                      if (itemStartDt != null) { //start date changed to date AFTER endDate Automatically adjust endDate

                          console.log('found start date, descriptionLabel: ', itemStartDt.descriptionLabel);

                          this.startDt = itemStartDt.date;

                          const s1 = this.datepipe.transform(new Date(this.startDt).getTime() , this.coreService.DATETIME_FORMAT);
                          console.log('automatically updating endDt: ');
                          this.endDt = this.datepipe.transform(new Date(new Date(s1).getTime() + 45 * 60000) , this.coreService.DATETIME_FORMAT); // + 45min
                          this.coreService.setDatePickerDate(new DatePickerData( this.endDt, 'End Date'));
                          console.log(this.endDt);
                          this.durationNegative = false;
                      }
                  } else {
                      this.durationNegative = false;
                  }

                  console.log('durationNegative: ', this.durationNegative);

                  this.duration = dif.toString();

                  // this.form = new FormGroup({
                  //     notes: new FormControl(this.form.value.notes, {
                  //         updateOn: 'blur',
                  //         validators: []
                  //     })
                  // });
              }
          );

      this.submitSectionSub = this.coreService.submitSectionsChanged.subscribe(
          submitSectionDataItems  => {
              console.log('submitSectionSub: ');
              console.log(submitSectionDataItems);

              const submitSectionItem = submitSectionDataItems.find(x => x.sectionName === this.formName);

              if (submitSectionItem){
                  if (submitSectionItem.cancelBroadcasted === true) {

                      console.log('lecture-new.submitSectionSub: redirecting to lecture-subjects');
                      this.router.navigateByUrl('/calendar/LECTURE');
                  }

                  if (submitSectionItem.submitBroadcasted === true) {

                      console.log('lecture-new.submitSectionSub: adding lecture');
                      this.onAddLecture();
                  }
              }
          }
      );
  }

    closeMessage() {
        this.operationCompleted = false;
        this.router.navigateByUrl('/lecture-subjects');
    }

    onAddLecture() {
    console.log('onAddLecture');

    //const startDt = this.selectedDate; // this.form.value.startDt; // new Date(this.form.value.startDt).getUTCDate();
    console.log('startDt');
    console.log(this.startDt);

    console.log('subjectIdFromForm');
    console.log(this.subjectIdFromForm);

    console.log('selectedClasses');
    console.log(this.selectedClasses);

    this.coreService.addLecture(this.subjectIdFromForm, this.selectedClasses, this.startDt.toString(), this.endDt.toString(),  this.notes, this.profileData.username)
        .subscribe(r => {

            // Sync all from db
            this.coreService.loadAllEvents().subscribe(allEvents => {
                this.calendarService.syncEventsFromDb(allEvents);
            });

          console.log(r);
          this.coreService.broadcastToastMessage('Lecture Added', false);
          this.router.navigateByUrl('/calendar/LECTURE');
        }, err => {
            console.log(err);
            //this.coreService.broadcastErrorMessage('Adding lecture failed ', err);
            this.isLoading = false;
        });
  }
}


