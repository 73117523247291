import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {take, map, tap, delay, switchMap} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {HttpService} from './http.service';
import {DatePipe} from '@angular/common';
import {CoreService} from './core.service';
import {url} from 'inspector';
import {TranslateService} from '@ngx-translate/core';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {Platform} from '@ionic/angular';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';

@Injectable({
  providedIn: 'root'
})
export class CachingService {
    public SFPC_LS_SETTING_NAME = 'DEV_SIMULATE_FAILED_POST_CACHED';
    private storage: SQLiteObject;
    // songsList = new BehaviorSubject([]);
    private isDbReady: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(private http: HttpClient,
                private router: Router,
                private cookieService: CookieService,
                private translate: TranslateService,
                private fileOpener: FileOpener,
                private platform: Platform,
                private sqlite: SQLite) {


        // if platform != android - return nulls or empty strings to simulate none or incorrect
        this.platform.ready().then(() => {
            // if (this.platform.is('android')) {
            //     this.sqlite.create({
            //         name: 'heavis_cache.db',
            //         location: 'default'
            //     })
            //         .then((db: SQLiteObject) => {
            //             this.storage = db;
            //             this.setupCacheSource();
            //         });
            // }else{
            //     // browser mode - skipping setup
            // }
        });

    }

    dbState() {
        return this.isDbReady.asObservable();
    }


    setupCacheSource() {
        return this.storage.executeSql('create table IF NOT EXISTS heavis_cache_table(entry_name TEXT, data TEXT)', [])
            .then(res => {
               console.log('cache source initiated');
               this.clearCache();
            });
    }


    // https://www.positronx.io/ionic-sqlite-database-crud-app-example-tutorial/
    // createCache(keyName: string, keyValue: string): Promise<string> {
    //
    //     let data = [keyName, keyValue];
    //     return this.storage.executeSql('INSERT INTO heavis_cache_table (entry_name, data) VALUES (?, ?)', data).then(res => {
    //         console.log( keyName + ' saved');
    //         return keyName + ' saved';
    //     })
    //     .catch(e => console.log('createCache error: ' + e));
    // }

    // https://www.positronx.io/ionic-sqlite-database-crud-app-example-tutorial/
    createCache(keyName: string, keyValue: string): Promise<string> {
        if (this.platform.is('android')) {
            const data = [keyName, keyValue];
            return this.storage.executeSql('INSERT INTO heavis_cache_table (entry_name, data) VALUES (?, ?)', data).then(res => {
                console.log(res);
                return res.toString();
            })
                .catch(e => console.log('deleteCacheItem error: ' + e));
        } else {
          const p = Promise.resolve(null);
          return Promise.resolve(p);
        }

    }

    deleteCacheItem(keyName): Promise<string> {
        return this.storage.executeSql('DELETE FROM heavis_cache_table where entry_name=?', [keyName]).then(res => {
            console.log(res);
            return res.toString();
        })
        .catch(e => console.log('deleteCacheItem error: ' + e));
    }

    clearCache(): Promise<string>  {
        return this.storage.executeSql('DELETE FROM heavis_cache_table', []).then(res => {
            console.log(res);
            return res.toString();
        })
        .catch(e => console.log('clearCache error: ' + e));
    }

    getCacheValue(keyName): Promise<any> {
         if (this.platform.is('android')) {
            return this.storage.executeSql('SELECT * FROM heavis_cache_table where entry_name=?', [keyName]).then(res => {
                console.log(res);
                try {
                    return res.rows.item(0).data;
                } catch {
                    return null;
                }
            })
                .catch(e => console.log('getCacheValue error: ' + e));

        } else {
            const p = Promise.resolve(null);
            return Promise.resolve(p);
        }
    }

}
