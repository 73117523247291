import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {take, map, tap, delay, switchMap, catchError} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {sha256} from 'js-sha256';
import has = Reflect.has;
import {File} from '@ionic-native/file/ngx';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {Platform} from '@ionic/angular';
import {CachingService} from './caching.service';
import { from } from 'rxjs';
import {CoreService} from './core.service';
import {CommonService} from './common.service';
import {Datasources} from '../models/datasource.types';
import {LogService} from './log.service';
import {StateMessage} from '../models/StateMessage.model';
import {environment} from '../../environment';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    private tokenCookie = null;
    private tokenPublicCookie = null;

    private langCookie = 'en';
    loginStateChanged = new EventEmitter<string>();
    languageChanged = new EventEmitter<string>();

    httpResponseCodeReceived = new EventEmitter<any>();
    httpService_stateMessageChanged = new EventEmitter<StateMessage>();

    constructor(
        private http: HttpClient,
        private router: Router,
        private cookieService: CookieService,
        private translate: TranslateService,
        private file: File,
        private fileOpener: FileOpener,
        private platform: Platform,
        private cachingService: CachingService,
        private logService: LogService) {
    }

    public isLoggedIn() {
        console.log('this.cookieService.get(token)');
        console.log(this.cookieService.get('token'));

        console.log('tokenCookie');
        console.log(this.tokenCookie);

        if (this.tokenCookie === undefined || this.tokenCookie === null || this.tokenCookie === '' ) {
            this.tokenCookie = this.getTokenFromCookie();
            return this.validateTokenCookie();
        } else {
            return this.validateTokenCookie();
        }
    }

    public isLoggedInPublic() {
        console.log('this.cookieService.get(token_public)');
        console.log(this.cookieService.get('token_public'));

        console.log('token_public');
        console.log(this.tokenPublicCookie);

        if (this.tokenPublicCookie === undefined || this.tokenPublicCookie === null || this.tokenPublicCookie === '' ) {
            this.tokenPublicCookie = this.getTokenPublicFromCookie();
            return this.validatePublicTokenCookie();
        } else {
            return this.validatePublicTokenCookie();
        }
    }

    validateTokenCookie() {
        console.log('validateTokenCookie');

        let validationResult = false;

        if (this.tokenCookie === undefined || this.tokenCookie === null || this.tokenCookie === ''  ) {
            validationResult = false;
        } else {

            console.log('token cookie length: ', this.tokenCookie.length);
            if (this.tokenCookie.length > 20) {
                validationResult = true;
            }
        }

        console.log('validationResult: ', validationResult);
        return validationResult;
    }

    validatePublicTokenCookie() {
        console.log('validatePublicTokenCookie');

        let validationResult = false;

        if (this.tokenPublicCookie === undefined || this.tokenPublicCookie === null || this.tokenPublicCookie === ''  ) {
            validationResult = false;
        } else {

            console.log('token_public cookie length: ', this.tokenPublicCookie.length);
            if (this.tokenPublicCookie.length > 20) {
                validationResult = true;
            }
        }

        console.log('validationResult: ', validationResult);
        return validationResult;
    }

    getTokenFromCookie() {
        const tokenFromCookie =  this.cookieService.get('token');
        this.tokenCookie = tokenFromCookie;
        console.log('getTokenFromCookie');
        console.log(tokenFromCookie);

        return tokenFromCookie;
    }

    getTokenPublicFromCookie() {
        const tokenFromCookie =  this.cookieService.get('token_public');
        this.tokenPublicCookie = tokenFromCookie;
        console.log('getTokenPublicFromCookie');
        console.log(tokenFromCookie);

        return tokenFromCookie;
    }

    public checkAuth() {
        this.tokenCookie = this.cookieService.get('token');
        //
        // if (!this.tokenCookie){
        //     if (!this.cookieService.get('token')){
        //         this.router.navigateByUrl('/login');
        //     } else {
        //         this.tokenCookie = this.cookieService.get('token');
        //     }
        // }
    }

    public logout() {
        console.log('logout');
        this.tokenCookie = null;
        this.cookieService.delete( 'token' , '/' );
        this.loginStateChanged.emit('loggedOut');
    }

    login0(txtPhoneCountryCode: string, txtPhoneNumber: string) {

        const fullPhoneNumber = '(' + txtPhoneCountryCode + ',' + txtPhoneNumber + ')';
        this.httpService_stateMessageChanged.emit(new StateMessage('logging in...', 'logging in: ' + fullPhoneNumber));

        console.log(txtPhoneCountryCode, txtPhoneNumber);

        console.log('login from httpService');

        return this.postPublic(environment.AUTH_ROOT_URL + '/rpc/totp_send', { _mobile: fullPhoneNumber }, true)
            .pipe(map(r => {
                    console.log('login response: ', r);
                    return r['otp'];
                },
                error => {
                    console.log('error during totp_send: ', error.message);
                    this.httpResponseCodeReceived.emit({status: '500', data: error});
                    return error.message;

                }));



        // return this.http.post(environment.AUTH_ROOT_URL + '/rpc/totp_send', { _mobile: fullPhoneNumber })
        //     .pipe(map(r => {
        //             console.log('login response: ', r);
        //             return r['otp'];
        //         }
        //     )), catchError((err) => {
        //
        //     const message = 'totp_send RESPONSE ERROR: ' + url;
        //     console.log(message);
        //     this.httpResponseCodeReceived.emit({status: '500', data: err});
        //     //return err;
        // });
    }

    login(otp: string, txtPhoneCountryCode: string, txtPhoneNumber: string) {

        const fullPhoneNumber = '(' + txtPhoneCountryCode + ',' + txtPhoneNumber + ')';

        console.log(txtPhoneCountryCode, txtPhoneNumber);
     //   this.httpService_stateMessageChanged.emit(new StateMessage('logging in...', 'logging in: ' + username));


        console.log('login from httpService');

        const postData = { _mobile: fullPhoneNumber, _otp: otp };
        console.log(postData);

        return this.postTotpLogin(environment.AUTH_ROOT_URL + '/rpc/totp_login', postData, true)
            .pipe(map(r => {
                    console.log('login response: ', r);
                    this.cookieService.set('token', r['token'], 60, '');
                    this.tokenCookie = r['token'];
                    console.log('token: ', this.tokenCookie);

                    this.loginStateChanged.emit('loggedIn');
                    return this.tokenCookie;
                },
                error => {
                    console.log('error during totp_login: ', error.message);
                    this.httpResponseCodeReceived.emit({status: '500', data: error});
                    return error.message;

                }));
    }

    loginPublicFunctions() {

        console.log('loginPublicFunctions');
        this.cookieService.set('token_public', 'xxx', 60, '');
        this.tokenPublicCookie = 'xxx';

        // console.log('login from httpService');
        // return this.http.post(environment.AUTH_ROOT_URL + '/rpc/login', { username: 'kark', pass: 'Gleba-Kurfa-Rutkowski-Patrol' })
        //     .pipe(map(r => {
        //             console.log('login response: ', r[0]);
        //             this.cookieService.set('token_public', r[0].token, 60, '');
        //             this.tokenPublicCookie = r[0].token;
        //             console.log('token_public: ', this.tokenPublicCookie);
        //             return this.tokenPublicCookie;
        //         }
        //     ));
    }

    setLanguage(token: string) {
        console.log('getLangCookie');

        console.log('login from httpService');
        return this.get(environment.URL_ROOT + '/Profile', ) // , token)
            .pipe(map(profileData => {

                    let langDataToUse = 'en';
                    if (profileData != null) {
                        if (profileData.length > 0) {
                            langDataToUse = profileData[0].language.toString().toLowerCase();
                        }
                    }

                    console.log('login response: ', profileData);
                    this.cookieService.set('lang', langDataToUse, 60, '');
                    this.langCookie = langDataToUse;


                    console.log('langDataToUse: ', langDataToUse);
                    console.log('lang: ', this.langCookie);
                    this.languageChanged.emit(this.langCookie);

                    //this.translate.setDefaultLang(this.langCookie);

                    return this.langCookie;

                }, err => {
                    this.langCookie = 'en';
                    this.cookieService.set('lang', 'en', 60, '');

                    //this.translate.setDefaultLang('en');

                    return 'en';
                }
            ));
    }

    // TODO: remove 'externalToken' ?
    get(url: string, externalToken = null, publicCall: boolean = false): Observable<any> {

        this.httpService_stateMessageChanged.emit(new StateMessage('RETRIEVING DATA . . .', 'retrieving data...: GET: ' + url));

       // this.core.emit({status: '400', data: err});
        this.getTokenFromCookie();

        let tokenToUse = this.tokenCookie;
        if (externalToken != null) {
            tokenToUse = this.tokenCookie;
        }

        if (publicCall) {
            tokenToUse = this.tokenPublicCookie;
        }

        // console.log('HTTP GET, tokenCookie: ', tokenToUse);

        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + tokenToUse,
            'X-User-Group': environment.DB_USER_GROUP
        });

        // console.log('tokenCookie: ', tokenToUse);


        console.log('url: ', url);
        this.logService.createLog(this.logService.HTTP_REQUEST_TYPE.GET,
            this.logService.HTTP_REQUEST_DIRECTION.REQUEST,
            url,
            JSON.stringify(headers), '');

        return this.http.get (url, {headers, observe: 'response'})
            .pipe(map(getResponse => {
                console.log(getResponse);

                this.logService.createLog(this.logService.HTTP_REQUEST_TYPE.GET,
                    this.logService.HTTP_REQUEST_DIRECTION.RESPONSE,
                    url,
                    '',
                    JSON.stringify(getResponse));

                this.httpResponseCodeReceived.emit({status: getResponse.status.toString(), data: getResponse});

                return getResponse.body;
            }), catchError((err) => {

                const message = 'GET RESPONSE ERROR: ' + url;
                console.log(message);
                this.httpResponseCodeReceived.emit({status: '400', data: err});
                return err;
            }));
    }

    getPublic(url: string, externalToken = null, publicCall: boolean = false): Observable<any> {

        this.httpService_stateMessageChanged.emit(new StateMessage('RETRIEVING DATA . . .', 'retrieving data...: GET: ' + url));

        // this.core.emit({status: '400', data: err});
        this.getTokenFromCookie();

        let tokenToUse = this.tokenCookie;
        if (externalToken != null) {
            tokenToUse = this.tokenCookie;
        }

        if (publicCall) {
            tokenToUse = this.tokenPublicCookie;
        }

        console.log('HTTP GET, tokenCookie: ', tokenToUse);

        const headers = new HttpHeaders({ });

        console.log('tokenCookie: ', tokenToUse);


        console.log('url: ', url);
        this.logService.createLog(this.logService.HTTP_REQUEST_TYPE.GET,
            this.logService.HTTP_REQUEST_DIRECTION.REQUEST,
            url,
            JSON.stringify(headers), '');

        return this.http.get (url, {headers, observe: 'response'})
            .pipe(map(getResponse => {
                console.log(getResponse);

                this.logService.createLog(this.logService.HTTP_REQUEST_TYPE.GET,
                    this.logService.HTTP_REQUEST_DIRECTION.RESPONSE,
                    url,
                    '',
                    JSON.stringify(getResponse));

                this.httpResponseCodeReceived.emit({status: getResponse.status.toString(), data: getResponse});

                return getResponse.body;
            }), catchError((err) => {

                const message = 'GET RESPONSE ERROR: ' + url;
                console.log(message);
                this.httpResponseCodeReceived.emit({status: '400', data: err});
                return err;
            }));
    }


    delete(url: string, externalToken = null, publicCall: boolean = false): Observable<any> {

        this.httpService_stateMessageChanged.emit(new StateMessage('RETRIEVING DATA . . .', 'retrieving data..: DELETE: ' + url));

        this.getTokenFromCookie();

        let tokenToUse = this.tokenCookie;
        if (externalToken != null) {
            tokenToUse = this.tokenCookie;
        }

        if (publicCall) {
            tokenToUse = this.tokenPublicCookie;
        }

        console.log('HTTP DELETE, tokenCookie: ', tokenToUse);

        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + tokenToUse
        });

        console.log('tokenCookie: ', tokenToUse);

        //  const options = { headers };
        // console.log(options);

        console.log('url: ', url);
        this.logService.createLog(this.logService.HTTP_REQUEST_TYPE.GET,
            this.logService.HTTP_REQUEST_DIRECTION.REQUEST,
            url,
            JSON.stringify(headers), '');

        return this.http.delete (url, {headers, observe: 'response'})
            .pipe(map(getResponse => {
                console.log(getResponse);

                this.logService.createLog(this.logService.HTTP_REQUEST_TYPE.GET,
                    this.logService.HTTP_REQUEST_DIRECTION.RESPONSE,
                    url,
                    '',
                    JSON.stringify(getResponse));

                this.httpResponseCodeReceived.emit({status: getResponse.status.toString(), data: getResponse});

                return getResponse.body;
            }), catchError((err) => {

                const message = 'DELETE RESPONSE ERROR: ' + url;
                console.log(message);
                this.httpResponseCodeReceived.emit({status: '400', data: err});
                return err;
            }));
    }

    get_CIVILIAN(url: string, externalToken = null): Observable<any> {
        return this.http.get (url, {  responseType: 'text'})
            .pipe(map(getResponse => {
//                console.log(getResponse);
                return getResponse;
            }));
    }

    postTotpLogin(url: string, postData: any, publicCall: boolean = false): Observable<any> {

        this.httpService_stateMessageChanged.emit(new StateMessage('RETRIEVING DATA . . .', 'retrieving data..: POST: ' + url));


        console.log('post: ' + url);
        this.getTokenFromCookie();
        console.log('HTTP POST, tokenCookie: ', this.tokenCookie);
        console.log('POSTDATA: (not printing in console)');
        // console.log(postData);

        let tokenToUse = this.tokenCookie;
        if (publicCall) {
            tokenToUse = this.tokenPublicCookie;
        }

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-User-Group': environment.DB_USER_GROUP
        });

        //  const options = {headers};

        this.logService.createLog(this.logService.HTTP_REQUEST_TYPE.POST, this.logService.HTTP_REQUEST_DIRECTION.REQUEST, url, JSON.stringify(headers), JSON.stringify(postData));

        return this.http.post(url, postData, {headers, observe: 'response'})
            .pipe(map(postResponse => {

                this.logService.createLog(this.logService.HTTP_REQUEST_TYPE.POST, this.logService.HTTP_REQUEST_DIRECTION.RESPONSE, url, '', JSON.stringify(postResponse.body));
                // console.log(postResponse);
                console.log('http.service.post => returning postResponse');
                this.httpResponseCodeReceived.emit({status: postResponse.status.toString(), data: postResponse});
                return postResponse.body;
            }), catchError((err) => {

                const message = 'POST ERROR: ' + url;
                console.log(message);
                console.log(err);

                this.httpResponseCodeReceived.emit({status: '400', data: err});
                return err;

            }));
    }

    postPublic(url: string, postData: any, publicCall: boolean = false): Observable<any> {

        this.httpService_stateMessageChanged.emit(new StateMessage('RETRIEVING DATA . . .', 'retrieving data..: POST: ' + url));

        console.log('post: ' + url);
        this.getTokenFromCookie();
        console.log('HTTP POST, tokenCookie: ', this.tokenCookie);
        console.log('POSTDATA: (not printing in console)');
        // console.log(postData);

        let tokenToUse = this.tokenCookie;
        if (publicCall) {
            tokenToUse = this.tokenPublicCookie;
        }

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Prefer: 'return=representation',
            'X-User-Group': environment.DB_USER_GROUP,
            hash: ''
        });

        //  const options = {headers};

        this.logService.createLog(this.logService.HTTP_REQUEST_TYPE.POST, this.logService.HTTP_REQUEST_DIRECTION.REQUEST, url, JSON.stringify(headers), JSON.stringify(postData));

        return this.http.post(url, postData, {headers, observe: 'response'})
            .pipe(map(postResponse => {

                this.logService.createLog(this.logService.HTTP_REQUEST_TYPE.POST, this.logService.HTTP_REQUEST_DIRECTION.RESPONSE, url, '', JSON.stringify(postResponse.body));
                // console.log(postResponse);
                console.log('http.service.post => returning postResponse');
                this.httpResponseCodeReceived.emit({status: postResponse.status.toString(), data: postResponse});
                return postResponse.body;
            }), catchError((err) => {

                // const message = 'POST ERROR: ' + url;
                // console.log(message);
                // this.httpResponseCodeReceived.emit({status: '400', data: err});
                // return err;

                const message = 'POST ERROR: ' + url;
                console.log(message);
                console.log(err);
                //
                // const formattedErrorMessage = 'url: ' + url + '<br />postdata:' +  JSON.stringify(postData) + '<br />headers: ' + JSON.stringify(headersToUse) + '<br />error: ' + JSON.stringify(err);
                //
                // this.httpResponseMessageReceived.emit('httpResponseMessageReceived: ' + formattedErrorMessage);
                this.httpResponseCodeReceived.emit({status: '400', data: err});
                return err;

            }));
    }

    post(url: string, postData: any, publicCall: boolean = false): Observable<any> {

        this.httpService_stateMessageChanged.emit(new StateMessage('RETRIEVING DATA . . .', 'retrieving data..: POST: ' + url));
        console.log('post: ' + url);
        this.getTokenFromCookie();
        console.log('HTTP POST, tokenCookie: ', this.tokenCookie);
        console.log('POSTDATA: (not printing in console)');
        // console.log(postData);

        let tokenToUse = this.tokenCookie;
        if (publicCall) {
            tokenToUse = this.tokenPublicCookie;
        }

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Prefer: 'return=representation',
            Authorization: 'Bearer ' + tokenToUse,
            'X-User-Group': environment.DB_USER_GROUP,
            hash: '1',
            'Access-Control-Allow-Origin': 'true'
        });

        //  const options = {headers};

        this.logService.createLog(this.logService.HTTP_REQUEST_TYPE.POST, this.logService.HTTP_REQUEST_DIRECTION.REQUEST, url, JSON.stringify(headers), JSON.stringify(postData));

        return this.http.post(url, postData, {headers, observe: 'response'})
            .pipe(map(postResponse => {

                this.logService.createLog(this.logService.HTTP_REQUEST_TYPE.POST, this.logService.HTTP_REQUEST_DIRECTION.RESPONSE, url, '', JSON.stringify(postResponse.body));
           //     console.log(postResponse);
                console.log('http.service.post => returning postResponse');
                this.httpResponseCodeReceived.emit({status: postResponse.status.toString(), data: postResponse});
                return postResponse.body;
            }), catchError((err) => {

                // const message = 'POST ERROR: ' + url;
                // console.log(message);
                // this.httpResponseCodeReceived.emit({status: '400', data: err});
                // return err;

                const message = 'POST ERROR: ' + url;
                console.log(message);
                console.log(err);
                //
                // const formattedErrorMessage = 'url: ' + url + '<br />postdata:' +  JSON.stringify(postData) + '<br />headers: ' + JSON.stringify(headersToUse) + '<br />error: ' + JSON.stringify(err);
                //
                // this.httpResponseMessageReceived.emit('httpResponseMessageReceived: ' + formattedErrorMessage);
                this.httpResponseCodeReceived.emit({status: '400', data: err});
                return err;

            }));
    }

    // https://web.dev/cache-api-quick-guide/ CACHED API
     async post_hashed(url: string, postData: any): Promise<any> {
         this.httpService_stateMessageChanged.emit(new StateMessage('RETRIEVING DATA . . .', 'retrieving DATA...: POST (hashed): ' + url));
         console.log('post_hashed: ' + url);

        // p_h_ -> post_hash
        // p_d_ -> post_data


        // 1. create method signature sha256(url + postdata)
        // 2. build ls value names:
        // - p_h_{signature} - where value will be hash of the data (Received from db)
        // - p_d_{signature} - where value will be base-64 encoded data (json - previously received from db)

        // 3. check if p_h_{signature} and p_d_{signature} - exist in LSpp-picker-date-time-c
        // 4. if it does exist - add heaader:
        //  hash: p_h_{signature}.value
        // 5. if response is with code 204 - decode p_d_{signature} and return as result
        // 6. If response is with code 200 - return result directly - and save new ls values for p_h_{signature} and p_d_{signature}


        const methodSignatureString = url + JSON.stringify(postData);
        console.log('methodSignatureString: ', methodSignatureString);
        const methodSignature = sha256(methodSignatureString);
        console.log('methodSignature: ', methodSignature);

        const p_h_NAME = 'p_h_' + methodSignature;
        const p_d_NAME = 'p_d_' + methodSignature; // + '_0';

        // let p_h_VALUE_FROM_LS = localStorage.getItem(p_h_NAME);
        // let p_d_VALUE_FROM_LS = localStorage.getItem(p_d_NAME);

        //   let p_h_VALUE_FROM_LS =   this.cachingService.getCacheValue(p_h_NAME);
        // let p_d_VALUE_FROM_LS = await this.cachingService.getCacheValue(p_d_NAME);

        const p_h_VALUE_FROM_LS = await this.cachingService.getCacheValue(p_h_NAME);
        const p_d_VALUE_FROM_LS = await this.cachingService.getCacheValue(p_d_NAME);

        console.log('p_h_VALUE_FROM_LS: ', p_h_VALUE_FROM_LS);
        console.log('p_d_VALUE_FROM_LS: ', p_d_VALUE_FROM_LS);

        let hashToSend = '';

        if (p_h_VALUE_FROM_LS !== null) { // && p_d_VALUE_FROM_LS !== null) {

                  // const hashDataFromLsBase64 = decodeURIComponent(escape(window.atob(p_h_VALUE_FROM_LS)));
                  const hashDataFromLs = JSON.parse(p_h_VALUE_FROM_LS);
                  hashToSend = hashDataFromLs.hash;
                  console.log('using hash from LS: ', hashToSend);
        }


        this.getTokenFromCookie();
        console.log('HTTP POST, tokenCookie: ', this.tokenCookie);
        console.log('POSTDATA:');
        console.log(postData);
        const headers = new HttpHeaders({
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + this.tokenCookie,
                  'X-User-Group': environment.DB_USER_GROUP,
                  hash: hashToSend
              });

        const options = {headers};

              // ADDITIONAL HEADER ON RESPONSE REQUIRED!
              // Access-Control-Expose-Headers: Hash
              // https://stackoverflow.com/questions/21635651/angularjs-http-object-not-showing-all-headers-from-response

        let urlToUse = url; // environment.URL_ROOT + '/NONEXITINGURL'; //

        const SFPC_LS_SETTING_VALUE = localStorage.getItem(this.cachingService.SFPC_LS_SETTING_NAME);
        if (SFPC_LS_SETTING_VALUE !== null) {
                  if (SFPC_LS_SETTING_VALUE === '1') {
                      urlToUse = environment.URL_ROOT + '/NONEXITINGURL';
                  }
              }

        console.log('urlToUse: ', urlToUse);
        console.log('SFPC_LS_SETTING_VALUE: ', SFPC_LS_SETTING_VALUE);

        return this.http.post<any>(urlToUse, postData, {headers, observe: 'response'})
                  .pipe(map(postResponse => {



              this.httpService_stateMessageChanged.emit(new StateMessage('RETRIEVING DATA . . .', 'retrieving data...: POST: ' + urlToUse));

                      const hashHeaderFromResponse = postResponse.headers.get('hash');
                      console.log('received hash header: ', hashHeaderFromResponse);

                      if (postResponse.status === 204) { // cached data is VALID
                          const message = 'RETURNING DATA FROM CACHE: ' + url;
                          console.log(message);

                          return this.getCachedValue(p_h_VALUE_FROM_LS, p_d_NAME, true).then( x => {
                              const json = JSON.parse(x);
                              console.log(json);

                              const response = {
                                  data: json,
                                  dataSource: Datasources.TYPES.CACHE,
                                  message
                              };

                              return response;
                          });
                      } else if (postResponse.status === 200) {
                          const message = 'RETURNING DATA FROM DB: ' + url;
                          console.log(message);

                           // save new hash and body
                          this.createPostCache(p_h_NAME, p_d_NAME, methodSignature, hashHeaderFromResponse, JSON.stringify(postResponse.body));

                          console.log('postResponse:');
                          console.log(postResponse);

                          const response = {
                              data: postResponse.body,
                              dataSource: Datasources.TYPES.DB,
                              message
                          };

                          const p = Promise.resolve(response);
                          return Promise.resolve(p);
                      } else {

                      }
                      this.httpResponseCodeReceived.emit({status: postResponse.status.toString(), data: postResponse} );
                  } ), catchError((err) => {

                          const message = 'POST RESPONSE ERROR: RETURNING DATA FROM CACHE: ' + url;
                          console.log(message);
                          this.httpResponseCodeReceived.emit({status: '400', data: err});
                          // const jsonString = "[{\"school\":\"CAREERS\",\"name\":\"G C SCHOOL OF CAREERS ELLINIKO DIMOTIKO\",\"phone\":[\"22464420\"],\"students_count\":680},{\"school\":\"PEFKIOS\",\"name\":\"DIMOTIKO SCHOLEIO PEFKIOS GEORGIADIS (ENIAIO OLOIMERO)\",\"phone\":[\"22871503\",\"22871504\"],\"students_count\":701},{\"school\":\"STMARYEL\",\"name\":\"ST. MARYS SCHOOL ELLINOFONO\",\"phone\":[\"25878398\"],\"students_count\":695},{\"school\":\"AMERINT\",\"name\":\"THE AMERICAN INTERNATIONAL SCHOOL IN CYPRUS\",\"phone\":[\"22316345\"],\"students_count\":685},{\"school\":\"MORFOSIS\",\"name\":\"PRIVATE PRIMARY SCHOOL MORFOSIS\",\"phone\":[\"25716361\"],\"students_count\":1274},{\"school\":\"FALCON\",\"name\":\"FALCON SCHOOL\",\"phone\":[\"22424781\"],\"students_count\":643},{\"school\":\"GRAMMAR\",\"name\":\"THE GRAMMAR JUNIOR SCHOOL\",\"phone\":[\"22695600\"],\"students_count\":647},{\"school\":\"PASCAL\",\"name\":\"PASCAL PRIVATE PRIMARY SCHOOL LEFKOSIA\",\"phone\":[\"22509210\"],\"students_count\":659},{\"school\":\"PYTHAGORAS\",\"name\":\"THE PUPILS OF PYTHAGORAS\",\"phone\":[\"25370087\",\"25660087\"],\"students_count\":665},{\"school\":\"NEWHOPE\",\"name\":\"NEW HOPE (PRIVATE SPECIAL SCHOOL)\",\"phone\":[\"22494820\"],\"students_count\":654},{\"school\":\"JUNIOR\",\"name\":\"THE JUNIOR SCHOOL LEFKOSIA\",\"phone\":[\"22664855\"],\"students_count\":667}]";
                          //
                          //
                          // const json = JSON.parse(jsonString);
                          // const response = {
                          //     data: json,
                          //     message: message
                          // }
                          //
                          //
                          //
                          // console.log('xxxxx');
                          // return new Promise(resolve => {
                          //     resolve(response);
                          // });

                          return this.getCachedValue(p_h_VALUE_FROM_LS, p_d_NAME, true).then(x => {
                              console.log('cached value: ');
                              console.log(x);
                              const json = JSON.parse(x);
                              console.log(json);

                              const response = {
                                  data: json,
                                  dataSource: Datasources.TYPES.CACHE_OFFLINE,
                                  message
                              };

                              const p = Promise.resolve(response);
                              return Promise.resolve(p);
                          });
                      })
                  );
    }

    // https://blog.bitsrc.io/introduction-to-the-cache-storage-a-new-browser-cache-pwa-api-a5d7426a2456
    // ???
    createPostCache(p_h_NAME: string, p_d_NAME: string, methodSignature: string, hashFromDb: string, data: string) {

        this.httpService_stateMessageChanged.emit(new StateMessage('creating cache...', 'creating cache...: p_h_NAME: ' + p_h_NAME));
        const jsonStringData = data; // btoa(unescape(encodeURIComponent(data)));

        const MAX_PART_CHARS_LENGTH = 9999999999;

        const dataArray = [];

        let parts = 1;

        if (jsonStringData.length > MAX_PART_CHARS_LENGTH) {
            // calculate parts
            parts = jsonStringData.length / MAX_PART_CHARS_LENGTH;
            parts = Math.ceil(parts)  ;
            console.log('PARTS: ', parts);

            for (let a = 0; a < parts; a++) {
                const offset = a * MAX_PART_CHARS_LENGTH;

                let end =  (offset + MAX_PART_CHARS_LENGTH);
                if (end > jsonStringData.length) {
                    end = jsonStringData.length;
                }

                dataArray.push(jsonStringData.substring(offset, end ));
            }
        } else {
            dataArray.push(jsonStringData);
        }

        const hashData = {
            hash: hashFromDb,
            parts
        };

        // localStorage.setItem(p_h_NAME, hashFromDb);
        const stringifiedHashData = JSON.stringify(hashData);
        // const base64HashData = btoa(unescape(encodeURIComponent(stringifiedHashData)));

        this.cachingService.createCache(p_h_NAME, stringifiedHashData).then(r => {
            console.log(r);
        });
        // localStorage.setItem(p_h_NAME, base64HashData);

        let count = 0;
        dataArray.map(d => {
            console.log('LOCAL STORAGE SAVE ATTEMPT:');
            console.log('localStorage key name: ', p_d_NAME, '; chars count: ', d.length);

            this.cachingService.createCache(p_d_NAME + '_' + count, d).then(r => {
                console.log(r);
            });

 //           localStorage.setItem(p_d_NAME + '_' + count, d);
            count ++;
        });
    }

      async getCachedValue(p_h_valueFromLs: string, lsKeyName: string, valueIsBase64Encoded: boolean = false) {

          this.httpService_stateMessageChanged.emit(new StateMessage('RETRIEVING DATA . . .', 'retrieving data...: getCachedValue: ' + p_h_valueFromLs));
        const hashDataFromLsJsonString = p_h_valueFromLs; // decodeURIComponent(escape(window.atob(p_h_valueFromLs)));
        const hashDataFromLs = JSON.parse(hashDataFromLsJsonString);

        let jsonString = '';
        for (let a = 0; a < 1; a++) {
            const currentKeyName = lsKeyName + '_' + a;
            console.log('keyname: ', currentKeyName);

            const r = await this.cachingService.getCacheValue(currentKeyName);
            jsonString = jsonString + r;
        }

        const decodedValue = jsonString; // decodeURIComponent(escape(window.atob(base64String)));
        return decodedValue;
    }


    patch(url: string, postData: any, reportField: string = '', publicCall = false): Observable<any> {
        this.httpService_stateMessageChanged.emit(new StateMessage('RETRIEVING DATA . . .', 'retrieving data...: PATCH: ' + url));
        console.log('patch: ' + url);
        this.getTokenFromCookie();
        console.log('HTTP PATCH, tokenCookie: ', this.tokenCookie);
        console.log('POSTDATA: (not printing in console)');
        console.log(postData);


        let tokenToUse = this.tokenCookie;
        if (publicCall) {
            tokenToUse = this.tokenPublicCookie;
        }

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + tokenToUse,
            Prefer: 'return=representation',
            'X-User-Group': environment.DB_USER_GROUP,
            hash: ''
        });

        return this.http.patch(url, postData, {headers, observe: 'response'})
            .pipe(map(postResponse => {
                console.log('http.service.PATCH => returning PATCHResponse');
                this.httpResponseCodeReceived.emit({status: postResponse.status.toString(), data: postResponse});
                return postResponse.body;
            }),catchError((err) => {
                const message = 'PATCH ERROR: ' + url;
                console.log(message);

                if (err.error === undefined) {
                    const substituteMessage = message + '; hint unspecified'
                    this.httpResponseCodeReceived.emit({status: '400', data: substituteMessage});
                } else {
                    this.httpResponseCodeReceived.emit({status: '400', data: err});
                }

                return err;
            }));
    }


    listPhoneCountries() {


        const headers = new HttpHeaders({
            'X-User-Group': environment.DB_USER_GROUP
        });


        return this.http.get(environment.AUTH_ROOT_URL + '/countries', {headers})
            .pipe(map(r => {
                    console.log('countries response: ', r);
                    // @ts-ignore
                    return r;
                }
            ));
    }
}
