import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {take, map, tap, delay, switchMap} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {HttpService} from './http.service';
import {DatePipe} from '@angular/common';
import {CoreService} from './core.service';
import {url} from 'inspector';
import {Platform} from '@ionic/angular';
import {Calendar} from '@ionic-native/calendar/ngx';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

    public HEAVIS_CALENDAR_NAME = 'scoolmed_main';
    pageTitle = '';
    calendars = null;
    calendarsString = '';
    calendarToUse = null;
    calendarToUseString = '';
    addEventResult = '';
    calendarName = '';
    //eventsFromDb = [];
    eventsFromDbFiltered = [];
    foundEventsList = [];
    isSyncing = false;
    showingOnlyVisible = true;
    event_type = '';
    school_code = '';
    testDate = '';
    resultMessage = '';
    resultMessageCapacitor = '';
    constructor(private http: HttpClient,
                private cookieService: CookieService,
                private router: Router,
                private httpService: HttpService,
                private datepipe: DatePipe,
                private plt: Platform,

                private calendar: Calendar) {
        this.plt.ready().then(() => {
            if (this.plt.is('android') || this.plt.is('ios')) {
                this.calendar.listCalendars().then(data => {
                    this.calendars = data;
                    this.calendarsString = JSON.stringify(this.calendars);
                    this.calendarToUse = this.calendars.find(x => x.name === this.HEAVIS_CALENDAR_NAME);
                    this.calendarToUseString = JSON.stringify(this.calendarToUse);

                    //this.syncEventsFromDb();
                });
            }
        });
    }





    clearCalendar() {
        this.calendar.deleteCalendar(this.HEAVIS_CALENDAR_NAME).then(res => {
            console.log('deleting calendar: ', this.HEAVIS_CALENDAR_NAME);
        }, err => {
            this.addEventResult = err;
            console.log('err: ', err);
        });

        this.calendar.createCalendar(this.HEAVIS_CALENDAR_NAME).then(res => {
            console.log('creating calendar: ', this.HEAVIS_CALENDAR_NAME);
        }, err => {
            this.addEventResult = err;
            console.log('err: ', err);
        });
    }





    syncEventsFromDb(eventsFromDb: any) {
        this.isSyncing = true;

     //   this.clearCalendar();

        this.calendar.deleteCalendar(this.HEAVIS_CALENDAR_NAME).then(r1 => {
            console.log('deleting calendar: ', this.HEAVIS_CALENDAR_NAME);

            this.calendar.createCalendar(this.HEAVIS_CALENDAR_NAME).then(r2 => {

                this.calendarToUse = this.calendars.find(x => x.name === this.HEAVIS_CALENDAR_NAME);
                console.log('creating calendar: ', this.HEAVIS_CALENDAR_NAME);
                //this.loadEvents(true, this.event_type);

                eventsFromDb.map(ev => {
                    let options = {
                        calendarId: this.calendarToUse.id,
                        calendarName: this.calendarToUse.name,
                        url: '', //this.coreService.URL_ROOT,
                        firstReminderMinutes: 15 };

                    // add event
                    const date = new Date(ev.start_dt);

                    console.log('before adding to calendar: ');
                    console.log(ev);
                    const eventName = ev.event_type + ': ' + ev.event_location;
                    this.calendar.createEventWithOptions(eventName, ev.event_location, ev.event_type, date, date, options).then(r3 => {
                        this.addEventResult = r3;
                    }, err => {
                        this.addEventResult = err;
                        console.log('err: ', err);
                    });
                });

                this.isSyncing = false;
            }, err => {
                this.addEventResult = err;
                console.log('err: ', err);
            });

        }, err => {
            this.addEventResult = err;
            console.log('err: ', err);
        });
    }

}
