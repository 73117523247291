import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router, RouterModule} from '@angular/router';
import {Calendar} from '@ionic-native/calendar/ngx';
import {ModalController} from '@ionic/angular';
import {QuickMenuComponent} from '../shared/quick-menu/quick-menu.component';
import {CoreService} from '../../services/core.service';
import {HttpService} from '../../services/http.service';
import {School} from '../shared/school.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ResourcesService} from '../../services/resources.service';
import {TranslateService} from '@ngx-translate/core';
import {CachingService} from '../../services/caching.service';
import {HomepageService} from '../../services/homepage.service';
import {Location} from '@angular/common';
import {version} from '../../../version';
import {TranslationsCommonService} from '../../services/translations.common.service';
import {environment} from '../../../environment';

@Component({
        selector: 'app-home',
        templateUrl: 'home.page.html',
        styleUrls: ['home.page.scss'],
})
export class HomePage implements OnInit, OnDestroy{

        currentDate = '';
        versionFromDownloadSite = '';
        showNewVersionInfo = false;
        keyName = 'key1';
        txt1 = 'xx';
        cres = '';
        cres2 = '';
        langCode = '';
        isLoggedIn = false;


        tileDataLoaded = false;
        tileData_Calendar = [];
        tileData_Calendar_LECTURE = [];
// tileData_Calendar_SCREENING = [];
        tileData_Calendar_VACCINES = [];

        tileData_Screening_BMI = [];
        tileData_Screening_AUDIO = [];
        tileData_Screening_CHROMA = [];
        tileData_Screening_SCOLIO = [];
        tileData_Screening_VISION = [];
        tileData_Students = [];

        tileData_Schools = [];


        tileData_References_BMI = [];
        tileData_References_AUDIO = [];
        tileData_References_CHROMA = [];
        tileData_References_SCOLIO = [];
        tileData_References_VISION = [];
        tileData_References_TOTAL_CLOSED = 0;
        tileData_References_TOTAL_OPEN = 0;
        tileData_References_TOTAL = 0;

        TRANSLATIONS = null;
        isStaging = false;

        tileData_Students_Loaded = false;
        tileData_Screening_Loaded = false;

        constructor(private router: Router,
                    private coreService: CoreService,
                    private httpService: HttpService,
                    private homepageService: HomepageService,
                    private location: Location,
                    private resourcesService: ResourcesService,
                    private translate: TranslateService,
                    private cacheService: CachingService,
                    private translationsCommonService: TranslationsCommonService) {



                if (environment.URL_ROOT.indexOf('sapi') > -1 || environment.AUTH_ROOT_URL.indexOf('spublic') > -1 ) {
                        this.isStaging = true;
                }

                setInterval(() => {
// this.versionFromDownloadSite = '';
                        this.checkVersionFromDownloadSite();
                }, 15000);

                location.onUrlChange((url, state) => {
                        console.log('URL CHANGE DETECTED! - from home.page.ts');
                        console.log(url);

                        setTimeout(() => {
                                if (!this.httpService.isLoggedIn()){
// this.router.navigateByUrl('/login');
                                } else {
                                        this.loadTileData();
                                }
                        }, 1500);
                });
        }

        private loginStateSub: Subscription;
        private languageSub: Subscription;
        private RefreshSub: Subscription;

        ngOnDestroy() {
                if (this.loginStateSub) {
                        this.loginStateSub.unsubscribe();
                }

                if (this.languageSub) {
                        this.languageSub.unsubscribe();
                }

                if (this.RefreshSub) {
                        this.RefreshSub.unsubscribe();
                }
        }

        ngOnInit() {
                if (!this.httpService.isLoggedIn()){
                        this.router.navigateByUrl('/login');
                } else {
                        this.currentDate = new Date().toLocaleString();

                        this.langCode = this.resourcesService.getLangCode();

                        console.log(this.TRANSLATIONS);
                        console.log('HOME PAGE ONINIT');
                        this.isLoggedIn = this.httpService.isLoggedIn();

                        this.loginStateSub = this.httpService.loginStateChanged.subscribe( r => {
                                    console.log('home: received loginState update event: ', r);
                                    this.isLoggedIn = this.httpService.isLoggedIn();
                            }
                        );

                        this.languageSub = this.httpService.languageChanged.subscribe(l => {
                                console.log('home: received languageChanged update event: ', l);
                                this.langCode = this.resourcesService.getLangCode();
                                this.translate.use(this.langCode);
                        });

                        this.checkVersionFromDownloadSite();

                        this.RefreshSub = this.coreService.RefreshChanged.subscribe(x => {
                                this.loadTileData();
                                this.checkVersionFromDownloadSite();
                        });

                        setTimeout(() => {
                                this.loadTileData();
                        }, 1500);
                }
        }

        disabledTileClick() {
                console.log('disabled tile clicked!');
        }

        checkVersionFromDownloadSite() {
// if (this.versionFromDownloadSite === '') {
                this.coreService.getVersionFromDownloadServer(this.isStaging).subscribe(r => {
                        this.versionFromDownloadSite = r.replace(' ', '').replace('\'', '').replace('\'', '').toString();


                        console.log('versionFromDownloadSite: ' + +this.versionFromDownloadSite);

                        console.log('appVersion: ' +  +version.app_version);


// if (this.versionFromDownloadSite.toString().indexOf(version.app_version.toString()) > -1) {
//     this.showNewVersionInfo = false;
// }else{
//     this.showNewVersionInfo = true;
// }


                        const versionFromDownloadSiteNumber = +this.versionFromDownloadSite;
                        // tslint:disable-next-line:variable-name
                        const app_versionNumber = +version.app_version;

                        if (Number.isNaN(versionFromDownloadSiteNumber)) {
                                this.showNewVersionInfo = false;
                        } else {
                                if (versionFromDownloadSiteNumber > app_versionNumber) {
                                        this.showNewVersionInfo = true;
                                } else {
                                        this.showNewVersionInfo = false;
                                }
                        }
                });
// }
        }

        downloadNewVersion() {
                if (this.isStaging) {
                        window.location.href = 'https://scoolmed.com/apk/scoolmed-debug.apk';
                } else {
                        window.location.href = 'https://scoolmed.com/apk/scoolmed.apk';
                }
        }

        loadPage(pageName: string) {
                console.log('loading page: ' + pageName);
                this.router.navigateByUrl('/' + pageName);
        }

        loadTileData() {

                this.coreService.loadEvents(true, null).subscribe(events => {
                        console.log('loadEvents for homepage');
                        console.log(events);

                        this.tileData_Calendar = events;

                        this.tileData_Calendar_LECTURE = events.filter(x => x.event_type === 'LECTURE').slice(0, 1);
//  this.tileData_Calendar_SCREENING = events.filter(x => x.event_type === 'SCREENING').slice(0, 1);
                        this.tileData_Calendar_VACCINES = events.filter(x => x.event_type === 'VACCINES').slice(0, 1);
                });

                this.homepageService.getSchoolsTileData().subscribe(r => {
                        console.log('getSchoolsTileData:');
                        console.log(r);
                        this.tileData_Schools = r;
                });


                this.homepageService.getStudentsTileData().subscribe(r => {
                        console.log('getStudentsTileData:');
                        console.log(r);
                        this.tileData_Students = r;
                        if (r?.length > 0) {
                                this.tileData_Students_Loaded = true;
                        }

                });

                this.homepageService.getScreeningTileData().subscribe(r => {
                        console.log('getScreeningTileData:');
                        console.log(r);
                        console.log(r.length);
                        this.tileData_Screening_BMI = r.filter(x => x.type === 'BMI');
                        this.tileData_Screening_AUDIO = r.filter(x => x.type === 'AUDIO');
                        this.tileData_Screening_CHROMA = r.filter(x => x.type === 'CHROMA');
                        this.tileData_Screening_SCOLIO = r.filter(x => x.type === 'SCOLIO');
                        this.tileData_Screening_VISION = r.filter(x => x.type === 'VISION');
                        console.log(this.tileData_Screening_BMI);
                        if (r?.length > 0) {
                                this.tileData_Screening_Loaded = true;
                        }
                });

                this.homepageService.getReferencesTileData().subscribe(r => {
                        console.log('getReferencesTileData:');
                        console.log(r);


                        this.tileData_References_BMI = r?.filter(x => x.type === 'BMI');
                        this.tileData_References_AUDIO = r?.filter(x => x.type === 'AUDIO');
                        this.tileData_References_CHROMA = r?.filter(x => x.type === 'CHROMA');
                        this.tileData_References_SCOLIO = r?.filter(x => x.type === 'SCOLIO');
                        this.tileData_References_VISION = r?.filter(x => x.type === 'VISION');

                        this.tileData_References_TOTAL_OPEN = +r?.filter(x => x.type === 'VISION')[0]?.open;
                        this.tileData_References_TOTAL_OPEN = +this.tileData_References_TOTAL_OPEN + +r?.filter(x => x.type === 'BMI')[0]?.open;
                        this.tileData_References_TOTAL_OPEN = +this.tileData_References_TOTAL_OPEN + +r?.filter(x => x.type === 'AUDIO')[0]?.open;
                        this.tileData_References_TOTAL_OPEN = +this.tileData_References_TOTAL_OPEN + +r?.filter(x => x.type === 'SCOLIO')[0]?.open;
                        this.tileData_References_TOTAL_OPEN = +this.tileData_References_TOTAL_OPEN + +r?.filter(x => x.type === 'CHROMA')[0]?.open;

                        this.tileData_References_TOTAL = +r?.filter(x => x.type === 'VISION')[0]?.total;
                        this.tileData_References_TOTAL = +this.tileData_References_TOTAL + +r?.filter(x => x.type === 'BMI')[0]?.total;
                        this.tileData_References_TOTAL = +this.tileData_References_TOTAL + +r?.filter(x => x.type === 'AUDIO')[0]?.total;
                        this.tileData_References_TOTAL = +this.tileData_References_TOTAL + +r?.filter(x => x.type === 'SCOLIO')[0]?.total;
                        this.tileData_References_TOTAL = +this.tileData_References_TOTAL + +r?.filter(x => x.type === 'CHROMA')[0]?.total;

                        this.tileData_References_TOTAL_CLOSED = +r?.filter(x => x.type === 'VISION')[0]?.closed;
                        this.tileData_References_TOTAL_CLOSED = +this.tileData_References_TOTAL_CLOSED + +r?.filter(x => x.type === 'BMI')[0]?.closed;
                        this.tileData_References_TOTAL_CLOSED = +this.tileData_References_TOTAL_CLOSED + +r?.filter(x => x.type === 'AUDIO')[0]?.closed;
                        this.tileData_References_TOTAL_CLOSED = +this.tileData_References_TOTAL_CLOSED + +r?.filter(x => x.type === 'SCOLIO')[0]?.closed;
                        this.tileData_References_TOTAL_CLOSED = +this.tileData_References_TOTAL_CLOSED + +r?.filter(x => x.type === 'CHROMA')[0]?.closed;

                        if ( isNaN(this.tileData_References_TOTAL_CLOSED) ) {
                                this.tileData_References_TOTAL_CLOSED = 0;
                        }

                        if ( isNaN(this.tileData_References_TOTAL) ) {
                                this.tileData_References_TOTAL = 0;
                        }

                        if ( isNaN(this.tileData_References_TOTAL_OPEN) ) {
                                this.tileData_References_TOTAL_OPEN = 0;
                        }

                        console.log('total open:', this.tileData_References_TOTAL_OPEN);

                        this.tileDataLoaded = true;
                });
        }


        loadSupportChat() {

// <ion-button onclick="window.open('https://matrix.to/#/!TYMJyUoAourUWhToWo:matrix.gnuhost.eu?via=matrix.gnuhost.eu', '_system', 'location=yes'); return=false;"></ion-button>

// https://matrix.to/#/!puXyYdiTwpoMKVuYTh:matrix.gnuhost.eu?via=matrix.gnuhost.eu
        }
}
