import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {take, map, tap, delay, switchMap} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {HttpService} from './http.service';
import {DatePipe} from '@angular/common';
import {CoreService} from './core.service';
import {url} from 'inspector';
import {School} from '../components/shared/school.model';
import {environment} from '../../environment';

@Injectable({
  providedIn: 'root'
})
export class SchoolsService {

    schoolsSelectedChanged = new EventEmitter<School[]>();
    schoolsAvailableChanged = new EventEmitter<School[]>();
    constructor(private coreService: CoreService,
                private http: HttpClient,
                private cookieService: CookieService,
                private router: Router,
                private httpService: HttpService,
                private datepipe: DatePipe) {
    }

    private _schoolsSelected: School[] = [];
    private _schoolsAvailable: School[] = [];

    private processAvailableSchools(rr: any) {
        const availableSchools = [];


        // {
        //     "school_code": "PASCALIM",
        //     "school": "ac73a385-58da-42fb-935b-1d01893b6b45",
        //     "name": "PASCAL LIMASSOL)",
        //     "type": "P",
        //     "phone": [
        //     "22871503",
        //     "22871504"
        // ],
        //     "address": "3 Deligianni",
        //     "post_code": "2023 Strovolos",
        //     "fax": "",
        //     "email": "dim-pefkiosgeorgiadis-lef@schools.ac.cy",
        //     "city": "Lemesos",
        //     "students_count": 1000
        // },


        console.log('processAvailableSchools');
        console.log(rr);

        rr.map(x => {

            /*
                    public school_id: string,
        public school_code: string,
        public school_name: string,
        public students_count: string
             */

            const newSchool =   new School (
                  x.school_id,
                  x.school_id,
                  x.name,
                  x.students
        );
//            console.log('newSchool');
//            console.log(newSchool);

            availableSchools.push(newSchool);
        });

        // rr.data.map(x => {
        //     availableSchools.push(new School(x.school_code, x.name, x.students_count));
        // });

//        console.log(availableSchools);

        this.coreService.broadcastDataSourceInformation(rr.dataSource, rr.message, false);
        this._schoolsAvailable = availableSchools;
        console.log('emitting schoolsAvailableChanged');
        this.schoolsAvailableChanged.emit(this._schoolsAvailable.slice());
//        console.log(this._schoolsAvailable);

        return this._schoolsAvailable;
    }

    loadSchoolDetails(visitor: string, school: string) {
        console.log('loadSchoolDetails');
        return this.httpService.get(environment.URL_ROOT + '/GetSchoolsOfVisitors?visitor=eq.' + visitor + '&school_id=eq.' + school)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    // load schools formatted (with student count etc) - for listing purposes
    loadSchools(username: string) {

        const postData = { _username: username };

        console.log('loadSchools');
        console.log(postData);

        // return this.httpService.post (environment.URL_ROOT + '/rpc/GetSchoolsOfVisitor', postData  )
        //     .pipe(
        //         map(r => {
        //             return this.processAvailableSchools(r);
        //         })
        //     );



        return this.httpService.get(environment.URL_ROOT + '/GetSchoolsOfVisitors?visitor=eq.' + username)
            .subscribe(response => {
                // console.log('loading aGetStudentsBySchool:');
                // console.log(response);

                return this.processAvailableSchools(response);

                // response.subscribe(rr => {
                //     console.log('result from subscribe (coreService.post_hashed)');
                //     console.log(rr);
                //
                //     if (rr === undefined) {
                //         console.log('rr is undefined');
                //     } else {
                //         console.log('rr:');
                //         console.log(rr);
                //
                //         if (rr.data) {
                //             return this.processAvailableSchools(rr.data);
                //         } else {
                //             rr.then(x2 => {
                //                 return this.processAvailableSchools(x2.data);
                //             });
                //         }
                //     }
                // });
            });
    }

    // raw schools View (for sign up purposes)
    getSchools() {
        return this.httpService.post(environment.URL_ROOT + '/rpc/GetAllSchools', {})
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }

    addSchool(  code: string,
                name: string,
                type: string,
                phone: string,
                address: string,
                post_code: string,
                fax: string,
                email: string,
                city: string) {

        const postData = {
            _code:  code,
            _name:  name,
            _type: type,
            _phone: [phone],
            _address: address,
            _post_code: post_code,
            _fax: fax,
            _email: email,
            _city: city
        };

        console.log(postData);

        return this.httpService.post(environment.URL_ROOT + '/rpc/addSchool', postData)
            .pipe(map(r => {
                console.log(r);
                return r;
            }));
    }



    setSelectedSchoolsByCode(newListOfSelectedSchools: string[]) {

        console.log('schoolsAvailable');
        console.log(this._schoolsAvailable);

        console.log('setSelectedSchools:');
        console.log(newListOfSelectedSchools);

        const newSelectedSchools = [];

        newListOfSelectedSchools.map(x => {
            console.log(x);

            const schoolToAdd = this._schoolsAvailable.find(i => i.school_code === x);
            console.log('SCHOOL TO ADD:');
            console.log(schoolToAdd);
            if (schoolToAdd) {
                newSelectedSchools.push(schoolToAdd);
            }
        });

        this._schoolsSelected = newSelectedSchools;

        this.schoolsSelectedChanged.emit(this._schoolsSelected.slice());
        console.log('_schoolsSelected:');
        console.log(this._schoolsSelected);
    }


    setSelectedSchools(newListOfSelectedSchools: string[]) {
        console.log('schoolsAvailable');
//        console.log(this._schoolsAvailable);

        console.log('setSelectedSchools:');
        //       console.log(newListOfSelectedSchools);

        const newSelectedSchools = [];

        newListOfSelectedSchools.map(x => {
            console.log(x);

            const schoolToAdd = this._schoolsAvailable.find(i => i.school_id === x);
            console.log('SCHOOL TO ADD:');
            console.log(schoolToAdd);
            if (schoolToAdd) {
                newSelectedSchools.push(schoolToAdd);
            }
        });

        this._schoolsSelected = newSelectedSchools;

        this.schoolsSelectedChanged.emit(this._schoolsSelected.slice());
        console.log('_schoolsSelected:');
        console.log(this._schoolsSelected);
    }

    // CACHE_OLD_loadSchools(code: string = null) {
    //     let urlParams = '';
    //     if (code !== null) {
    //         urlParams = '?code=eq.' + code;
    //     }
    //
    //     console.log(urlParams);
    //
    //     return this.httpService.post_hashed(this.URL_ROOT + '/rpc/getSchools', {})
    //         .then(response => {
    //                 console.log('loading available schools:');
    //                 console.log(response);
    //                 response.subscribe(rr => {
    //                     console.log('result from subscribe (coreService.post_hashed)');
    //                     console.log(rr);
    //
    //                     if (rr === undefined) {
    //                         console.log('rr is undefined');
    //                     } else {
    //                         console.log('rr:');
    //                         console.log(rr);
    //
    //                         if (rr.data) {
    //                             return this.processAvailableSchools(rr);
    //                         } else {
    //                             rr.then(x2 => {
    //                                 return this.processAvailableSchools(x2);
    //                             });
    //                         }
    //                     }
    //                 });
    //             });
    // }
}
