import {Injectable} from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class CommonSharedService {

    constructor() {
    }


    getDatePipe_DateTimeFormat(){
        return 'dd/MM/yyyy HH:mm';
    }


}
