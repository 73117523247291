import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CoreService} from '../../../services/core.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LoadingController, ModalController} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {School} from '../school.model';
import {DatePipe, Location} from '@angular/common';
import {HttpService} from '../../../services/http.service';
import {SchoolsService} from '../../../services/schools.service';
import {IdentityService} from "../../../services/identity.service";

@Component({
  selector: 'app-school-selector',
  templateUrl: './school-selector.component.html',
  styleUrls: ['./school-selector.component.scss'],
})
export class SchoolSelectorComponent implements OnInit, OnDestroy {
  @Input() isMultipleSelection = true;

  private availableSchoolsSub: Subscription;
  private selectedSchoolsSub: Subscription;

  availableSchools = [];
  selectedSchools = [];
  isLoading = false;
  touched = false;

  noSchoolInfoVisible = false;

  constructor(private coreService: CoreService,
              private schoolsService: SchoolsService,
              private route: ActivatedRoute,
              private router: Router,
              private loadingCtrl: LoadingController,
              private modalCtrl: ModalController,
              private location: Location,
              private datepipe: DatePipe,
              private httpService: HttpService,
              private identityService: IdentityService) {

      // location.onUrlChange((url, state) => {
      //     this.ngOnInit();
      // });
  }

  ngOnDestroy() {
    if (this.availableSchoolsSub) {
      this.availableSchoolsSub.unsubscribe();
    }

    if (this.selectedSchoolsSub) {
      this.selectedSchoolsSub.unsubscribe();
    }
  }

  ngOnInit() {
      if (!this.httpService.isLoggedIn()){
          this.router.navigateByUrl('/login');
      }

      console.log('school-selector ngOnInit');

      this.isLoading = true;
      this.availableSchoolsSub = this.schoolsService.schoolsAvailableChanged
        .subscribe(
            (availableSchools: School[]) => {
              this.availableSchools = availableSchools;

              console.log('schoolsAvailableChanged - in subscription response');
              console.log(availableSchools);
              this.isLoading = false;
            }
        );

      this.schoolsService.schoolsAvailableChanged
        .subscribe(
            (availableSchools: School[]) => {
              this.availableSchools = availableSchools;

              console.log('schoolsAvailableChanged - in subscription response');
              console.log(availableSchools);
              this.isLoading = false;
            }
        );

      // const xx =  this.schoolsService.loadSchools().subscribe(as => {
      //           console.log('xx: ', xx);
      // });

      this.schoolsService.schoolsSelectedChanged
        .subscribe(
            (selectedSchools: School[]) => {
              this.selectedSchools = selectedSchools;
              console.log('school-selector schoolsSelectedChanged - in subscription response');
              console.log(selectedSchools);
            }
        );


      setTimeout(() => {
          if (this.availableSchools.length < 1) {

              this.identityService.profile().subscribe(res => {
                  if (res !== null) {
                      if (res.length > 0) {
                          this.schoolsService.loadSchools(res[0].username);
                      }
                  }
              }, err => {
                  console.log(err);
              });
          }
      }, 1000);

      setTimeout(() => { // delay displaying NO_SCHOOL_INFO. This is to prevent displaying NO SCHOOL message - during school loading time
        this.noSchoolInfoVisible = true;
      }, 3000);
  }

  updateSelection(schoolId: string) {
    console.log('updateSelection');
    console.log('form.value.schools:');

    const schoolsToUse = [];
    schoolsToUse.push(schoolId);

    this.schoolsService.setSelectedSchools(schoolsToUse);
    this.touched = true;

    this.modalCtrl.dismiss();
  }

    redirectToHomepage() {
        this.modalCtrl.dismiss();
        this.router.navigateByUrl('home');
    }
}
