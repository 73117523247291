import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreService} from '../../services/core.service';
import {ModalController, ToastController} from '@ionic/angular';
import {HttpService} from '../../services/http.service';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe} from '@angular/common';
import {FormGroup} from '@angular/forms';
import {SchoolClass} from '../shared/schoolclass.model';
import {DatePickerData} from '../shared/datepickerdata.model';
import {ScreeningService} from '../../services/screening.service';
import {Location} from '@angular/common';
import {TranslationsCommonService} from '../../services/translations.common.service';
import {CalendarService} from '../../services/calendar.service';

@Component({
  selector: 'app-screening-add',
  templateUrl: './screening-add.component.html',
  styleUrls: ['./screening-add.component.scss'],
})
export class ScreeningAddComponent implements OnInit {

  school = '';

  operationCompleted = false;

  formName = 'addScreening' + this.coreService.generateFormNamePostfix();

  startDt1 = this.datepipe.transform(new Date(new Date().getTime() + 1440 * 60000) , this.coreService.DATETIME_FORMAT);   // +24h
  endDt1 = this.datepipe.transform(new Date(new Date().getTime() + 1485 * 60000) , this.coreService.DATETIME_FORMAT); // + 24h, 45min

  startDt = this.datepipe.transform(new Date(new Date(this.startDt1).getTime() ) , this.coreService.DATE_FORMAT) + " 08:00";   // +24h
  endDt = this.datepipe.transform(new Date(new Date(this.endDt1).getTime() ) , this.coreService.DATE_FORMAT) + " 08:45"; // + 24h, 45min
  durationNegative = false;
  duration = '45';

  notes = '';

  initialDateStart =   this.datepipe.transform(new Date() , this.coreService.DATETIME_FORMAT);
  initialDateEnd = this.datepipe.transform(new Date(new Date().getTime() + 45 * 60000) , this.coreService.DATETIME_FORMAT);

  formVisible = true;

  SELECTED_TEST_bmi = true;
  SELECTED_TEST_chroma = true;
  SELECTED_TEST_vision = true;
  SELECTED_TEST_audio = true;
  SELECTED_TEST_scolio = true;


    private submitSectionSub: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private coreService: CoreService,
              private screeningService: ScreeningService,
              private modalCtrl: ModalController,
              private httpService: HttpService,
              private translate: TranslateService,
              private datepipe: DatePipe,
              public toastController: ToastController,
              private location: Location,
              private translationsCommonService: TranslationsCommonService,
              private calendarService: CalendarService) {
      location.onUrlChange((url, state) => {
          this.ngOnInit();
      });
  }

  private selectedClassesSub: Subscription;
  private datePickerDatesChangedSub: Subscription;

  selectedDate = '';
  selectedClasses = [];
  isLoading = false;

  ngOnDestroy() {
    if (this.submitSectionSub) {
      this.submitSectionSub.unsubscribe();
    }

    if (this.selectedClassesSub) {
      this.selectedClassesSub.unsubscribe();
    }

    if (this.datePickerDatesChangedSub) {
      this.datePickerDatesChangedSub.unsubscribe();
    }
  }

  ngOnInit() {

     if (!this.httpService.isLoggedIn()){
          this.router.navigateByUrl('/login');
      }


     this.school = this.route.snapshot.paramMap.get('school') === null ? '' : this.route.snapshot.paramMap.get('school');
     if (this.school !== '') {
         this.formVisible = true;
     }

    this.selectedClassesSub = this.coreService.classesSelectedChanged
        .subscribe(
            (classes: SchoolClass[]) => {
              console.log('classesSelectedChanged: ');
              console.log(classes);

              this.selectedClasses = [];
              classes.map(x => {
                this.selectedClasses.push(x.class_code);
              });
            }
        );

    this.datePickerDatesChangedSub = this.coreService.datePickerDatesChanged
        .subscribe(
            dates  => {
              console.log('datePickerDatesChangedSub: ');
              console.log(dates);

              const itemStartDt = dates.find(x => x.descriptionLabel === 'Start Date');

              if (itemStartDt != null){
                this.startDt = itemStartDt.date;
              }

              const itemEndDate = dates.find(x => x.descriptionLabel === 'End Date');
              if (itemEndDate != null){
                this.endDt = itemEndDate.date;
              }

              console.log('calculating duration');
              let dif = (+(new Date(this.endDt)) - +(new Date(this.startDt)));
              dif = Math.round((dif / 1000 ) / 60);

              console.log(dif);

              if (+dif < 0) {
                this.durationNegative = true;
                if (itemStartDt != null) { //start date changed to date AFTER endDate Automatically adjust endDate

                  console.log('found start date, descriptionLabel: ', itemStartDt.descriptionLabel);

                  this.startDt = itemStartDt.date;

                  const s1 = this.datepipe.transform(new Date(this.startDt).getTime(), this.coreService.DATETIME_FORMAT);
                  console.log('automatically updating endDt: ');
                  this.endDt = this.datepipe.transform(new Date(new Date(s1).getTime() + 45 * 60000) , this.coreService.DATETIME_FORMAT); // +   45min
                  this.coreService.setDatePickerDate(new DatePickerData( this.endDt, 'End Date'));
                  console.log(this.endDt);
                  this.durationNegative = false;
                }
              } else {
                this.durationNegative = false;
              }

              console.log('durationNegative: ', this.durationNegative);
              this.duration = dif.toString();
            }
        );

    this.submitSectionSub = this.coreService.submitSectionsChanged.subscribe(
        submitSectionDataItems  => {
          console.log('submitSectionSub: ');
          console.log(submitSectionDataItems);
          const submitSectionItem = submitSectionDataItems.find(x => x.sectionName === this.formName);

          if (submitSectionItem){
            if (submitSectionItem.cancelBroadcasted === true) {
              this.router.navigateByUrl('/calendar');
            }

            if (submitSectionItem.submitBroadcasted === true) {
              this.onAddScreeningEvent();
            }
          }
        }
    );
  }

  onAddScreeningEvent() {
    console.log(this.startDt);
    console.log(this.selectedClasses);

    const SELECTED_TESTS = [];
    if (this.SELECTED_TEST_bmi)    { SELECTED_TESTS.push('BMI');    }
    if (this.SELECTED_TEST_chroma) { SELECTED_TESTS.push('CHROMA'); }
    if (this.SELECTED_TEST_vision) { SELECTED_TESTS.push('VISION'); }
    if (this.SELECTED_TEST_audio)  { SELECTED_TESTS.push('AUDIO');  }
    if (this.SELECTED_TEST_scolio) { SELECTED_TESTS.push('SCOLIO'); }


    this.screeningService
        .screening_createEvent(this.startDt.toString(), this.endDt.toString(), this.selectedClasses, this.notes, SELECTED_TESTS)
        .subscribe(r => {
          console.log(r);
          this.coreService.broadcastToastMessage(this.translationsCommonService.TRNSL_DATA_SAVED_SUCCESSFULLY, false);
          this.formVisible = false;
            // Sync all from db
            this.coreService.loadAllEvents().subscribe(allEvents => {
                this.calendarService.syncEventsFromDb(allEvents);
            });

        }, err => {
          console.log(err);
          this.coreService.broadcastErrorMessage(this.translationsCommonService.TRNSL_DATA_SAVING_FAILED, err);
          this.isLoading = false;
        });
  }
}
