import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CoreService} from '../../services/core.service';
import {ActivatedRoute, ActivationStart, Router, RouterOutlet} from '@angular/router';
import {LoadingController, ModalController, Platform} from '@ionic/angular';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Event} from '../shared/event.model';
import {Subscription} from 'rxjs';
import {HttpService} from '../../services/http.service';
import {PickerDateTimeComponent} from '../shared/picker-date-time/picker-date-time.component';
import {DatePipe} from '@angular/common';
import {ResourcesService} from '../../services/resources.service';
import {DatePickerData} from '../shared/datepickerdata.model';
import {Location} from '@angular/common';
import {CalendarService} from '../../services/calendar.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-calendar-event-details',
  templateUrl: './calendar-event-details.component.html',
  styleUrls: ['./calendar-event-details.component.scss'],
})
export class CalendarEventDetailsComponent implements OnInit, OnDestroy {
  COMPONENT_NAME = 'calendar-event-details';
  isLoading = false;
  eventId = '';
  eventData = null;
  isEditable = false;

  startDt = '2020-12-01';
  endDt = '2020-12-01';

  notes = '';

  startDt_ICS = '2020-12-01';
  endDt_ICS = '2020-12-01';

  durationNegative = false;

  creatorData = '';

  newDisputeMessage = '';

  msgOperationResult = '';

  formVisible = true;
  visitors = [];
  disputeMessages = [];

  operationCompleted = false;
  message = '';

  duration = '45';
  platformMobile = true;

  private datePickerDatesChangedSub: Subscription;
  private submitSectionSub: Subscription;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private coreService: CoreService,
      private loadingCtrl: LoadingController,
      private modalCtrl: ModalController,
      private datepipe: DatePipe,
      private httpService: HttpService,
      private resourcesService: ResourcesService,
      private location: Location,
      private calendarService: CalendarService,
      private platform: Platform) {

    // location.onUrlChange((url, state) => {
    //   this.ngOnInit();
    // });

    if (this.platform.is('desktop') || this.platform.is('mobileweb')  ) {
      this.platformMobile = false;
    } else {
      this.platformMobile = true;
    }
  }

  ngOnDestroy() {
    if (this.datePickerDatesChangedSub) {
      this.datePickerDatesChangedSub.unsubscribe();
    }

    if (this.submitSectionSub) {
      this.submitSectionSub.unsubscribe();
    }
  }

  ngOnInit() {

    if (!this.httpService.isLoggedIn()){
      this.router.navigateByUrl('/login');
    }

    this.eventId = this.route.snapshot.paramMap.get('eventId');

    console.log('event-edit onInit');
    console.log(this.eventId);
    console.log('eventId from componentProps ', this.eventId);

    this.loadEventData();

    this.datePickerDatesChangedSub = this.coreService.datePickerDatesChanged
        .subscribe(
            dates  => {
              console.log('datePickerDatesChangedSub: ');
              console.log(dates);

              const itemStartDt = dates.find(x => x.descriptionLabel === 'Start Date');

              if (itemStartDt != null){
                this.startDt = itemStartDt.date;
              }

              const itemEndDate = dates.find(x => x.descriptionLabel === 'End Date');
              if (itemEndDate != null){
                this.endDt = itemEndDate.date;
              }

              console.log('calculating duration');
              let dif = (+(new Date(this.endDt)) - +(new Date(this.startDt)));
              dif = Math.round((dif / 1000 ) / 60);

              console.log(dif);

              if (+dif < 0) {
                this.durationNegative = true;

                if (itemStartDt != null) { //start date changed to date AFTER endDate Automatically adjust endDate
                  this.startDt = itemStartDt.date;

                  const s1 = this.datepipe.transform(new Date(this.startDt).getTime() , this.coreService.DATETIME_FORMAT);
                  console.log('automatically updating endDt: ');
                  this.endDt = this.datepipe.transform(new Date(new Date(s1).getTime() + 45 * 60000) , this.coreService.DATETIME_FORMAT); // +   45min
                  this.coreService.setDatePickerDate(new DatePickerData( this.endDt, 'End Date'));
                  console.log(this.endDt);
                  this.durationNegative = false;
                }
              } else {
                this.durationNegative = false;
              }

              console.log('durationNegative: ', this.durationNegative);

              this.duration = dif.toString();

              // this.form = new FormGroup({
              //   notes: new FormControl(this.form.value.notes, {
              //     updateOn: 'blur',
              //     validators: []
              //   })
              // });
            }
        );


    this.submitSectionSub = this.coreService.submitSectionsChanged.subscribe(
        submitSectionDataItems  => {
          console.log('submitSectionSub: ');
          console.log(submitSectionDataItems);

          const submitSectionItem = submitSectionDataItems.find(x => x.sectionName === this.COMPONENT_NAME);

          if (submitSectionItem != null){
            if (submitSectionItem.cancelBroadcasted === true) {
              this.location.back();
            }

            if (submitSectionItem.submitBroadcasted === true) {
              this.onUpdateEvent();
            }
          }
        }
    );
  }

  loadEventData() {
    console.log('load event data...');
    this.isLoading = true;
    this.loadDisputeMessages();

   // this.coreService.getVisitors().subscribe(visitors => {
    //  this.visitors = visitors;
    //  console.log('visitors');
    //  console.log(this.visitors);

      console.log('calling coreService.getEvent:', this.eventId);

      this.coreService.getEvent(this.eventId).subscribe(eventData => {

        this.notes = eventData?.notes;

        console.log('getEvent from onInit');
        console.log(eventData);

        console.log('eventData.name');

        this.startDt =  this.datepipe.transform(eventData.start_dt, this.coreService.DATETIME_FORMAT);
        console.log('eventData.start_dt');
        console.log(this.startDt);


        this.startDt_ICS =  this.datepipe.transform(eventData.start_dt, this.coreService.DATETIME_FORMAT_ICS);
        console.log('eventData.startDt_ICS');
        console.log(this.startDt_ICS);

        this.endDt_ICS =  this.datepipe.transform(eventData.end_dt, this.coreService.DATETIME_FORMAT_ICS);
        console.log('eventData.end_dt');
        console.log( this.endDt_ICS);


        this.endDt =  this.datepipe.transform(eventData.end_dt, this.coreService.DATETIME_FORMAT);
        console.log('eventData.end_dt');
        console.log( this.endDt);

        this.eventData = eventData;

        if (this.eventData.From === 'null' || this.eventData.From === null || this.eventData.From === 'Me') {
        } else {
          this.creatorData = this.eventData.From;
        }

        console.log('available visitors (local):');
        console.log(this.visitors);

      //  console.log('selectedVisitor');
      //  const selectedVisitor = this.visitors.find(x => x.visitor === eventData.visitor);
      //  console.log(selectedVisitor);

        this.isLoading = false;
        this.isEditable = eventData.editable;

        // if (eventData.editable) {
        //   this.form = new FormGroup({
        //     notes: new FormControl(eventData.notes, {
        //       updateOn: 'blur',
        //       validators: []
        //     })
        //   });
        // }
        // });
   });
  }

  submitDispute(){
    console.log('submitting dispute');
    console.log('disputeMessage: ', this.newDisputeMessage);
    this.coreService.addDisputeMessage(this.newDisputeMessage, this.eventId).subscribe(r => {
      this.newDisputeMessage = '';

      this.coreService.getDisputeMessages(this.eventId).subscribe(disputes => {
        this.disputeMessages = disputes;
      });
    });
  }

  loadDisputeMessages() {
    this.coreService.getDisputeMessages(this.eventId).subscribe(disputes => {
      this.disputeMessages = disputes;
    });
  }

  isFormDisabled() {
    //returns value for [disabled] property to update button
    let startDtValid = false;
    try {
       const sd =  this.datepipe.transform(this.startDt, this.coreService.DATE_FORMAT) != null;
       startDtValid = true;
    } catch {

    }

    let endDtValid = false;
    try {
      const ed =  this.datepipe.transform(this.endDt, this.coreService.DATE_FORMAT) != null;
      endDtValid = true;
    } catch {

    }

    const disabled =    !this.eventData.is_editable || !startDtValid || !endDtValid || this.durationNegative === true;

    return disabled;
  }

  onUpdateEvent() {
    this.isLoading = true;
    console.log('onUpdateEvent');

    // if (!this.form.valid) {
    //   return;
    // }

    const updatedEvent = new Event(
        this.startDt,
        this.endDt,
        this.duration,
        null,
        null,
       '',// this.form.value.name,
        '',// this.form.value.location,
        this.notes, //this.form.value.notes,
        this.eventId,
        true);


    this.coreService.updateEvent(updatedEvent).subscribe(r => {
      //this.form.reset();
      this.message = 'Event Updated';

      this.operationCompleted = true;
      this.eventData = r[0];
      this.loadEventData();
      this.isLoading = false;

      this.coreService.broadcastToastMessage('Event updated', false);

      // Sync all from db
      this.coreService.loadAllEvents().subscribe(allEvents => {
        this.calendarService.syncEventsFromDb(allEvents);
      });
    }, err => {
      this.message = err.message;
      this.operationCompleted = true;
      this.isLoading = false;
    });
  }

  markRead(disputeId: string, isRead: boolean) {
    if (!isRead) {
      this.coreService.disputeMarkRead(disputeId).subscribe(r => {
        console.log('markRead');
        this.loadDisputeMessages();
      });
    }
  }

  downloadIcs() {

    console.log('downloading ics');

    // test tool: https://openicsfile.com/csv-convert.html

    // example:

    // BEGIN:VCALENDAR
    // VERSION:2.0
    // PRODID:impact-calendar
    // BEGIN:VEVENT
    // UID:a5955b14f70c1722350db6b3b8eb6e15
    // SUMMARY:Sebastians birthday
    // DTSTAMP;TZID=Europe/Warsaw:20180201T090000
    // DTSTART;TZID=Europe/Warsaw:20180201T090000
    // DTEND;TZID=Europe/Warsaw:20180201T180000
    // DESCRIPTION:Cookies & cocktails!
    // LOCATION:Kruikstraat 22\, 2018 Antwerpen
    // END:VEVENT
    // END:VCALENDAR

    const formattedDate1 = this.startDt_ICS;
    const formattedDate2 = this.endDt_ICS;

//   const stringToHash = formattedDate1 + formattedDate2 + this.eventData.event_type + this.eventData.event_location_name;
    const newLineSymbol = '\n';
    const uid =  this.eventData.event;
    const text = 'BEGIN:VCALENDAR' + newLineSymbol +
        'VERSION:2.0' + newLineSymbol +
        'PRODID:impact-calendar' + newLineSymbol +
        'BEGIN:VEVENT' + newLineSymbol +
        'UID:' + uid + newLineSymbol +
        'SUMMARY:' + this.eventData.event_type + newLineSymbol +
        'DTSTAMP;TZID=Etc/UTC:' + formattedDate1 + newLineSymbol +
        'DTSTART;TZID=Etc/UTC:' + formattedDate1 + newLineSymbol +
        'DTEND;TZID=Etc/UTC:' + formattedDate2 + newLineSymbol +
        'DESCRIPTION:' + this.eventData.event_type + ': ' + this.eventData.event_location_name + newLineSymbol +
        'LOCATION:' + this.eventData.event_location_name + newLineSymbol +
        'END:VEVENT' + newLineSymbol +
        'END:VCALENDAR';

    console.log(text);

    const textBase64 = btoa(text);

    const icsPrefix = 'data:text/calendar;charset=utf8;base64,';

    const urlFinal = icsPrefix + textBase64;


    var icsFile = null;

    // @ts-ignore
    const data = new File([text], { type: 'text/plain' });

    // If we are replacing a previously generated file we need to
    // manually revoke the object URL to avoid memory leaks.
    if (icsFile !== null) {
      window.URL.revokeObjectURL(icsFile);
    }

    icsFile = window.URL.createObjectURL(data);

    console.log(icsFile);

    const blob = this.coreService.dataURItoBlob(urlFinal);
    saveAs(blob, 'event.ics');
    return icsFile;

  }

  deleteEvent() {
    this.coreService.deleteEvent(this.eventId).subscribe(eventData => {
      console.log('deleted event');
      this.location.back();
    });
  }

  // redirect to related school
  startScreening() {
    this.router.navigateByUrl('/students/school/' + this.eventData.event_location);
  }
}
