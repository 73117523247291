import {Component, OnDestroy, OnInit} from '@angular/core';
import {Calendar} from '@ionic-native/calendar/ngx';
import {CameraResultType, CameraSource, Capacitor, Plugins} from '@capacitor/core';
import {NavController, Platform} from '@ionic/angular';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CoreService} from '../../services/core.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpService} from '../../services/http.service';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {TranslationsCommonService} from '../../services/translations.common.service';
import {CalendarService} from '../../services/calendar.service';
import {Subscription} from 'rxjs';
import {ScreeningService} from '../../services/screening.service';
import {CommonSharedService} from "../../services_shared/common-shared.service";

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, OnDestroy {

    pageTitle = '';
    form: FormGroup;
    HEAVIS_CALENDAR_NAME = 'scoolmed_main';
    calendars = null;
    calendarsString = '';
    calendarToUse = null;
    calendarToUseString = '';
    addEventResult = '';
    calendarName = '';
    eventsFromDb = [];
    eventsFromDbFiltered = [];
    foundEventsList = [];
    isSyncing = false;
    showingOnlyVisible = true;
    event_type = '';
    school_code = '';
    testDate = '';
    resultMessage = '';
    resultMessageCapacitor = '';


    platformMobile = true;

    private CalendarSyncSub: Subscription;
    private CalendarScreeningSyncSub: Subscription;



    constructor(private router: Router,
                private route: ActivatedRoute,
                public navCtrl: NavController,
                private calendar: Calendar,
                private plt: Platform,
                private coreService: CoreService,
                private screeningService: ScreeningService,
                private calendarService: CalendarService,
                private httpService: HttpService,
                private location: Location,
                private translate: TranslateService,
                private platform: Platform,
                private translationsCommonService: TranslationsCommonService,
                public commonSharedService: CommonSharedService) {

        // location.onUrlChange((url, state) => {
        //     this.ngOnInit();
        // });

        if (this.platform.is('desktop') || this.platform.is('mobileweb')  ) {
            this.platformMobile = false;
        } else {
            this.platformMobile = true;
        }

    }

    getPageTitle() {
            if (this.event_type === 'LECTURE') {
               return this.translationsCommonService.TRNSL_LECTURES_PAGE_TITLE;
            } else if (this.event_type === 'SCREENING') {
                return this.translationsCommonService.TRNSL_SCREENING_PAGE_TITLE;
            } else {
                return this.translationsCommonService.TRNSL_CALENDAR_PAGE_TITLE;
            }
    }

    ngOnDestroy() {
        if (this.CalendarSyncSub) {
            this.CalendarSyncSub.unsubscribe();
        }

        if (this.CalendarScreeningSyncSub) {
            this.CalendarScreeningSyncSub.unsubscribe();
        }
    }

    ngOnInit() {

        if (!this.httpService.isLoggedIn()) {
            this.router.navigateByUrl('/login');
        }

        let event_typeFromUrl = this.route.snapshot.paramMap.get('event_type');
        this.event_type = event_typeFromUrl;

        let school_codeFromUrl = this.route.snapshot.paramMap.get('school_code');
        if (event_typeFromUrl !== null) {
            this.school_code = school_codeFromUrl;
            console.log('SCHOOL_CODE: ', this.school_code);
        }

        this.loadEvents(true, event_typeFromUrl);
        // this.plt.ready().then(() => {
        //     if (this.plt.is('android') || this.plt.is('ios')) {
        //         this.calendar.listCalendars().then(data => {
        //             this.calendars = data;
        //             this.calendarsString = JSON.stringify(this.calendars);
        //             this.calendarToUse = this.calendars.find(x => x.name === this.HEAVIS_CALENDAR_NAME);
        //             this.calendarToUseString = JSON.stringify(this.calendarToUse);
        //
        //             this.calendarService.syncEventsFromDb();
        //             this.loadEvents(true, this.event_type);
        //         });
        //     } else {
        //        this.loadEvents(true, this.event_type);
        //     }
        // });


        this.testDate = new Date('2021-01-23').toString();

        this.form = new FormGroup({
            eventName: new FormControl(null, {
                updateOn: 'blur',
                validators: [Validators.required]
            }),
            eventDate: new FormControl(null, {
                updateOn: 'blur',
                validators: [Validators.required]
            })
        });

        this.CalendarSyncSub = this.coreService.CalendarSyncChanged.subscribe( r => {
               console.log('CalendarSyncSub...');
               this.forceSync();
            }
        );

        this.CalendarScreeningSyncSub = this.screeningService.CalendarSyncChanged.subscribe( r => {
                console.log('CalendarScreeningSyncSub...');
                this.forceSync();
            }
        );
    }

    forceSync() {
        // Sync all from db
        this.coreService.loadAllEvents().subscribe(allEvents => {
            this.calendarService.syncEventsFromDb(allEvents);
        });

        this.loadEvents(true, this.event_type);
    }

    hideEvent(eventId: string) {
        this.coreService.hideEvent(eventId).subscribe(r => {
            console.log(eventId, ' hidden');
            this.loadEvents(true, null);

            const itemToRemove = this.eventsFromDb.find(x => x.event === eventId);
            const index = this.eventsFromDb.indexOf(itemToRemove);
            this.eventsFromDb.splice(index, 1);


            // Sync all from db
            this.coreService.loadAllEvents().subscribe(allEvents => {
                this.calendarService.syncEventsFromDb(allEvents);
            });
        });
    }

    navigateToEvent(item: any) {
        if (item?.InDays?.indexOf('-') > -1) {
            // TODO: fix this (use valid properties)
            const url = '/students/screening-report/' +  item.event_location + '/' + item.class + '/' + item.date_formatted;
            this.router.navigateByUrl(url);
        } else {
            this.router.navigateByUrl('/calendar-event/' + item.event);
        }
    }

    openCalendar() {
        this.calendar.openCalendar(new Date()).then(res => {
            console.log('opening native calendar');
        });
    }

    // clearCalendar() {
    //     this.calendar.deleteCalendar(this.HEAVIS_CALENDAR_NAME).then(res => {
    //                     console.log('deleting calendar: ', this.HEAVIS_CALENDAR_NAME);
    //                 }, err => {
    //                     this.addEventResult = err;
    //                     console.log('err: ', err);
    //                 });
    //
    //     this.calendar.createCalendar(this.HEAVIS_CALENDAR_NAME).then(res => {
    //         console.log('creating calendar: ', this.HEAVIS_CALENDAR_NAME);
    //     }, err => {
    //         this.addEventResult = err;
    //         console.log('err: ', err);
    //     });
    // }

    // syncEventsFromDb() {
    //     this.isSyncing = true;
    //
    //     this.clearCalendar();
    //
    //     this.calendar.deleteCalendar(this.HEAVIS_CALENDAR_NAME).then(r1 => {
    //         console.log('deleting calendar: ', this.HEAVIS_CALENDAR_NAME);
    //
    //         this.calendar.createCalendar(this.HEAVIS_CALENDAR_NAME).then(r2 => {
    //
    //             this.calendarToUse = this.calendars.find(x => x.name === this.HEAVIS_CALENDAR_NAME);
    //             console.log('creating calendar: ', this.HEAVIS_CALENDAR_NAME);
    //             this.loadEvents(true, this.event_type);
    //
    //             this.eventsFromDb.map(ev => {
    //                 let options = {
    //                     calendarId: this.calendarToUse.id,
    //                     calendarName: this.calendarToUse.name,
    //                     url: this.coreService.URL_ROOT,
    //                     firstReminderMinutes: 15 };
    //
    //                 // add event
    //                 let date = new Date(ev.start_dt);
    //
    //                 console.log('before adding to calendar: ');
    //                 console.log(ev);
    //                 let eventName = ev.event_type_raw + ': ' + ev.event_location
    //                 this.calendar.createEventWithOptions(eventName, ev.event_location, ev.event_type, date, date, options).then(r3 => {
    //                     this.addEventResult = r3;
    //                 }, err => {
    //                     this.addEventResult = err;
    //                     console.log('err: ', err);
    //                 });
    //             });
    //
    //             this.isSyncing = false;
    //         }, err => {
    //             this.addEventResult = err;
    //             console.log('err: ', err);
    //         });
    //
    //     }, err => {
    //         this.addEventResult = err;
    //         console.log('err: ', err);
    //     });
    // }

    loadEvents(showOnlyVisible: boolean = true, eType: string) {


        showOnlyVisible = false;
        this.coreService.loadEvents(showOnlyVisible, eType).subscribe(events => {
            console.log('loading events: ', eType);
            console.log(events);

            this.showingOnlyVisible = showOnlyVisible;
            this.eventsFromDb = events;

            if (this.school_code !== '' && this.school_code !== null) {
                console.log('filtering by school code: ', this.school_code);
                this.eventsFromDb = this.eventsFromDb.filter(x => x.event_location === this.school_code);
            }

            this.eventsFromDbFiltered = this.eventsFromDb;
            if ( eType !== null) {
                this.eventsFromDbFiltered = this.eventsFromDbFiltered.filter(x => x.event_type === eType);
            }

            console.log('eType:' + eType);
            console.log('loading eventsFromDbFiltered');
            console.log( this.eventsFromDbFiltered);
        });

        // Sync all from db
        this.coreService.loadAllEvents().subscribe(allEvents => {
            this.calendarService.syncEventsFromDb(allEvents);
        });
    }

    createCalendar() {
          if (!Capacitor.isPluginAvailable('Calendar')) {
             console.log('capacitor Calendar NOT AVAILABLE');
             this.resultMessageCapacitor = 'capacitor Calendar NOT AVAILABLE';
          } else {
              console.log('capacitor Calendar IS AVAILABLE');
              this.resultMessageCapacitor = 'capacitor Calendar IS AVAILABLE';
          }

            // console.log('calndar available:',    Capacitor.isPluginAvailable('Calendar'));
            this.calendar.createCalendar('scoolmed_main').then(
                (msg) => { console.log(msg);
                this.resultMessage = msg;

                },
                (err) => { console.log(err);
                this.resultMessage = err;
                }
            );
    }

    listCalendars() {
      this.calendar.listCalendars().then(
          (msg) => {
              console.log(msg);
              this.resultMessage = this.resultMessage + 'listCalendars: ' + msg;
              this.calendars = msg;
          },
          (err) => {
              console.log(err);
              this.resultMessage = this.resultMessage + 'error: ' +  err;
          }
      );
    }

    formatSubtitle(text: any) {
        let textToPrint = '';

        if (this.event_type === 'LECTURE') {
            if (text === '' || text === null || text === undefined) {
                textToPrint = '';
            } else {
                textToPrint = text + ' ';
            }
        }

        return textToPrint;
    }
}
